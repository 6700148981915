/** @jsx jsx */
import React from 'react';
import { css, jsx } from "@emotion/core";
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import LOGOWHITE from '../img/logo-white.svg';
import SidebarMenuItem from '../components/SidebarMenuItemComponent';

const menuItems = [
  {
    path: "/overview",
    icon: "fa fa-tachometer",
    title: "Overview",
    subMenuItems: []
  },
  // {
  //     path:"/notifications",
  //     icon:"fa fa-bell",
  //     title:"Notifications",
  //     subMenuItems: []
  // },
  {
    path: "/marketing-materials",
    icon: "fa fa-hourglass",
    title: "Marketing Materials",
    subMenuItems: []
  },
  {
    path: "",
    icon: "fa fa-bar-chart",
    title: "Reporting",
    subMenuItems: [
      {
        title: "Activity",
        path: "/reports/activity"
      },
      {
        title: "Purchases",
        path: "/reports/purchases"
      }
    ]
  },
  {
    path: "/transactions",
    icon: "fa fa-credit-card",
    title: "Transactions",
    subMenuItems: []
  },
  {
    path: "/profile",
    icon: "fa fa-user",
    title: "My Profile",
    subMenuItems: []
  },
  {
    path: "/help",
    icon: "fa fa-question-circle",
    title: "Help",
    subMenuItems: []
  }
];

const column = css`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  .top-left {
    flex-shrink: 0;

    .sidebar__top {
      height: 8rem;
      display: flex;
      padding: 1.5rem 2rem;
      align-items: center;
      background-color: #5199D3;
      border-bottom: 1px solid rgba(33, 33, 33, 0.25);
    }
    
    .sidebar__top--affiliate {
      flex-direction: column;
      align-items: end; 
  
      p {
          color: #ffffff;
          font-weight: 800;
          margin-bottom: 1rem;
      
      }
    }
  }

  .bottom {
    flex-grow: 1;
    overflow-y: auto;
    position: relative;

    .dashboard__menu {
      height: 5rem;
      display: flex;
      padding-left: 2rem;
      align-items: center;
    
      a, p {
        text-decoration: none;
        color: #FFFFFF;
        font-style: normal;
        font-weight: 300;
        margin-right: 1rem;
        cursor: pointer;
      }
    
      .fa {
        margin-right: 1rem;
        color: #FFFFFF;
      }
    }
  }

  .sidebar--space-between {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .sidebar--affiliate {
    background-color: #5199D3;
  }

  .sidebar__bottom {
    display: flex;
    padding: 2rem;
    height: 8rem;
    flex-direction: column;
    background-color: #5199D3;
  }
`;

const sidebar = css`
  width: 20%;
  height: 100%;
  background-color: #FFFFFF;
  font-size: 1.6rem;
  min-width: 25rem; 

  ul {
    width: 100%;
  }
  
  @media screen and (max-width: 768px) {
    & {
        font-size: 1.4rem;
        min-width: 20rem;
    }
  }
`;

const active = css`
  display: flex;
  flex-direction: column;
  
  @media screen and (max-width: 768px) {
    & {
        display:none;
    }
  }
`;

class SidebarContainer extends React.Component {
  render() {
    const {showSideBar, user} = this.props;    
    const displaySideBar = () => {
      return !showSideBar && active
    };

    return (
      <div id="left" css={[column, sidebar, displaySideBar()]}>
        <div className="top-left">
          <div className="sidebar__top sidebar__top--affiliate">
              <p>
                {user.firstName}
              </p>
              <p>ID &nbsp; {user.affiliateCode}</p>
          </div>
        </div>
        <div className="bottom sidebar--space-between sidebar--affiliate">
          <nav>
            <ul>
              {menuItems.map((menuItem, i) => (
                <SidebarMenuItem
                  key={i + 1}
                  path={menuItem.path}
                  icon={menuItem.icon}
                  title={menuItem.title}
                  subMenuItems={menuItem.subMenuItems}/>
              ))}
              <li className="dashboard__menu">
                <i className="fa fa-sign-out"/>
                <a href="/logout">
                  Logout
                </a>
              </li> 
            </ul>
          </nav>
        </div>
        <div className="sidebar__bottom">
          <img src={LOGOWHITE} alt="RelianceHMO's Logo"/>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  const {showSideBar} = state.display || {};
  return {showSideBar};
}

export default withRouter(connect(mapStateToProps)(SidebarContainer));