import React, {Component} from 'react';
import {css} from 'react-emotion';
import axios from 'axios';
import moment from 'moment';
import numberFormatter from 'number-formatter';
import PropTypes from 'prop-types';
import LoadingScreen from './LoadingScreen';
import SortTableData from '../utils/SortTableData';
import StringManipulator from '../utils/StringManipulator';
import StyleColors from '../utils/StyleColors';

const defaultTableStyle = css`
  width: 100%;
  tbody {
    font-weight: 600;
    color: #333;
  }  
  th,td {
    height: 5rem;
    min-height: 5rem;
    font-size: 1.4rem;
    text-align: left;
    padding-left: 2rem;
    color: #212121;
    vertical-align: middle;
    font-weight:400;
  }
  th {
    background-color: rgba(81,153,211,0.04);;
    font-weight: 800;
    text-transform:capitalize;
  }   
`;

const defaultPaginatorStyle = css`
  display: flex;
  position: relative;
  bottom: 0;
  width: 100%;
  left: 0;
  justify-content: center;
  flex-direction: row;
  padding: 1rem;
  align-items: center;
  cursor: pointer;
  background:#fff;
  box-shadow: 0 0 1px 1px #dedede;
  
  button {
    padding: 1rem;
    background: white;
    border-radius: 0.01rem;
  }

  button:disabled {
    color: white;
  }

  button:nth-child(1) {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }

  button:nth-last-child(1) {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }

  button:focus {
    outline: none;
  }

  button.current {
    background: ${StyleColors.brand01};
    color: ${StyleColors.ui01};
  }

  p {
    margin: 1rem;
    font-size: 2rem;
  }
`;

class AsyncTable extends Component {
  constructor (props) {
    super(props);
    this.state = {
      isLoading:false,
      sortedData: [],
      tableData:[],
      currentPage:1,
      lastPage:null,
      isSorted:false,
      sortConfig:null
    }
  }

  componentDidMount(){
    this.fetchData(1);
  }

  fetchData = (page) => {
    this.setState({
      isLoading:true
    });
    const {dataSource,limit,paginationKeys,isPaginated} = this.props;
    const {isSorted, sortConfig} = this.state;
    let queryParams = isPaginated && paginationKeys ? `?${paginationKeys.page}=${page}&${paginationKeys.limit}=${limit}`: "";
    axios.get(
      `${dataSource.url}${queryParams}`,
        dataSource.options)
      .then(response=>{
        //do stuff with the response
        const paginationData = response.data.pagination || response.data;
        this.setState({
          isLoading:false,
          currentPage:isPaginated ? paginationData[paginationKeys.currentPage] || page : 1,
          lastPage: isPaginated ? paginationData[paginationKeys.lastPage] || 1 : 1,
          tableData: !isSorted ? response.data.data : sortConfig.order === "asc" ? SortTableData.ascendingTableSort(sortConfig.key, response.data.data) : SortTableData.descendingTableSort(sortConfig.key, response.data.data)
        })
      })
  };

  ascendingSort = (key) => {
    const sortedData = SortTableData.ascendingTableSort(key, this.state.tableData);
    this.setState({
      sortedData: sortedData,
      isSorted:true,
      sortConfig:{
        key,
        order:"asc"
      }
    })
  };

  descendingSort = (key) => {
    const sortedData = SortTableData.descendingTableSort(key, this.state.tableData);
    this.setState({
      sortedData: sortedData,
      isSorted:true,
      sortConfig:{
        key,
        order:"desc"
      }
    })
  };

  renderLoading = () => {
    return (
      <div style={{position: "absolute",height:"100%",left:"0",display:"flex",width:"100%",background:"#fffffa",top:"7rem"}}>
        <LoadingScreen/>
      </div>
    );
  };

  // renderNoData = () => {
  //   return (
  //     <div style={{position: "absolute",height:"100%",display:"flex",width:"100%",background:"#fffffa"}}>
  //       <CenteredContentBlock>
  //         <h1 style={{color:"#adabab",fontSize:"2.5rem"}}>Nothing to see here :'(</h1>
  //       </CenteredContentBlock>
  //     </div>
  //   );
  // };

  renderTableData = (tableData, tableColumnData) => {
    // if(tableData.length === 0) return this.renderNoData();

    return (
      <tbody>
      {
        tableData.map((data, i) => {
          return (
            <tr key={i+1}>
              {
                tableColumnData.map((colData, i) => (
                  <td key={i+1}>{colData.format ? colData.format(data[colData.key]) : formatDataBasedOnType(data[colData.key || colData],colData.type)}</td>
                ))
              }
            </tr>
          )
        })
      }
      </tbody>
    )
  };

  render() {
    const {isLoading, currentPage, lastPage, tableData} = this.state;
    const {tableColumnData,isPaginated, styles, styleClass, paginatorStyle} = this.props;

    // console.log(styles);

    return (
      <React.Fragment>
        <div style={{overflowX:"scroll"}}>
          <table className={styleClass || defaultTableStyle} style={styles}>
            <thead>
            <tr>
              {
                tableColumnData.map((data, i) => (
                  <th key={i+1} className="table-header">
                    <span>{StringManipulator.replaceCharWithSpace(data.alias || data.key || data)}</span>
                    <span className="table-caret">
                    <i className="fa fa-caret-up" onClick={() => this.ascendingSort(data.key || data)}/>
                    <i className="fa fa-caret-down" onClick={() => this.descendingSort(data.key) || data}/>
                  </span>
                  </th>
                ))
              }
            </tr>
            </thead>
            {this.renderTableData(tableData,tableColumnData)}
          </table>
        </div>
        {isPaginated &&
          <div className={paginatorStyle || defaultPaginatorStyle}>
            <Paginator currentPage={currentPage} lastPage={lastPage} onPageChange={this.fetchData}/>
          </div>
        }
        {isLoading && this.renderLoading()}
      </React.Fragment>
    )
  }
}

AsyncTable.propTypes = {
  tableColumnData: PropTypes.arrayOf(PropTypes.shape({
    key:PropTypes.string,
    dataType:PropTypes.string,
    alias:PropTypes.string,
    format:PropTypes.func
  })),
  styles: PropTypes.object,
  styleClass: PropTypes.string,
  paginatorStyle: PropTypes.string,
  dataSource: PropTypes.shape({
    url:PropTypes.string.isRequired,
    options:PropTypes.object //takes structure of axios request options/config
  }).isRequired,
  limit:PropTypes.number,
  isPaginated:PropTypes.bool,
  meta: PropTypes.object,
  paginationKeys: PropTypes.shape({
    currentPage: PropTypes.string,
    limit: PropTypes.string,
    count: PropTypes.string,
    lastPage: PropTypes.string,
    total: PropTypes.string,
    page: PropTypes.string
  })
};

const formatDataBasedOnType = (data,type="string") => {
  switch (type){
    case 'string':
      return data;
    case 'number':
      return numberFormatter("###,##0.",Number(data));
    case 'date':
      return moment(data).format("ddd, MMM Do YYYY");
    default:
      return data;
  }
};


class Paginator extends React.Component {

  render(){

    const {currentPage,lastPage, onPageChange,className} = this.props;

    return (
      <div className={className}>
        {<button disabled={currentPage === 1} onClick={()=>onPageChange(currentPage-1)}>Prev</button>}
        <React.Fragment>
          {currentPage !== 1 && <button onClick={()=>onPageChange(1)}>1</button>}

          {currentPage-3 > 1 && <p>...</p>}

          {currentPage-2 > 1 && <button onClick={()=>onPageChange(currentPage-2)}>{currentPage-2}</button>}
          {currentPage-1 > 1 && <button onClick={()=>onPageChange(currentPage-1)}>{currentPage-1}</button>}

          <button className="current">{currentPage}</button>

          {currentPage+1 < lastPage && <button onClick={()=>onPageChange(currentPage+1)}>{currentPage+1}</button>}
          {currentPage+2 < lastPage && <button onClick={()=>onPageChange(currentPage+2)}>{currentPage+2}</button>}

          {currentPage+3 < lastPage && <p disabled>...</p>}

          {currentPage !== lastPage && <button onClick={()=>onPageChange(lastPage)}>{lastPage}</button>}
        </React.Fragment>
        <button disabled={currentPage === lastPage} onClick={()=>onPageChange(currentPage+1)}>Next</button>
      </div>
    )
  }
}

Paginator.propTypes = {
  currentPage:PropTypes.number.isRequired,
  lastPage:PropTypes.number,
  onPageChange:PropTypes.func.isRequired
};

export default AsyncTable;