import React from 'react';
import {Field,reduxForm} from 'redux-form';
import PropTypes from 'prop-types';

class CashOutForm extends React.Component {

  render(){

    const {handleSubmit, pristine, submitting} = this.props;


    return (
      <form onSubmit={handleSubmit} className="l-form" style={{marginTop:"1rem"}}>
        <Field
          className="form-input"
          component="input"
          type="number"
          placeholder="Enter the amount you want to cash-out"
          name="amount"
          style={{margin:"1rem",width:"auto",minWidth:"20rem",padding:"1rem",fontSize:"1.2rem"}}
        />
        <button className="btn primary" disabled={pristine || submitting}>
          Cash-Out
        </button>
      </form>
    )
  }
}

CashOutForm.propTypes = {
  onSubmit:PropTypes.func
};

CashOutForm = reduxForm({
  form: "cashOutForm"
})(CashOutForm);

export default CashOutForm;