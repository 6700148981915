import {USER_ACTION_TYPES} from './actionTypes';
//import {setCookie,deleteCookie,DOMAIN_COOKIE_NAME} from "../services/utilityService";
import userService from '../services/userService';
import { sessionService } from 'redux-react-session';
import {getAccountsDashboardUrl} from "../services/utilityService";


//////////////////// ACTIONS //////////////////////////

const login = () => {
  return {
      type: USER_ACTION_TYPES.LOGIN
  }
};

const loginSuccessful = (client) => {
    return {
        type: USER_ACTION_TYPES.LOGIN_SUCCESS,
        data: client
    }
};

const loginFailed = (error) => {
    return {
        type: USER_ACTION_TYPES.LOGIN_FAIL,
        data: error
    }
};

export const daaraLogout = () => {
  return {
      type: USER_ACTION_TYPES.DAARA_LOGOUT
  }
};

export const daaraLogoutSuccessful = (data) => {
  return {
      type: USER_ACTION_TYPES.DAARA_LOGOUT_SUCCESS,
      data: { data }
  }
};

export const daaraLogoutFailed = (error) => {
  return {
      type: USER_ACTION_TYPES.DAARA_LOGOUT_FAIL,
      data: {
          code: error.code,
          message: error.message
      }
  }
};

const setLoading = () => {
    return {
        type: USER_ACTION_TYPES.START_LOADING
    }
};

const stopLoading = () => {
    return {
        type: USER_ACTION_TYPES.STOP_LOADING
    }
};

export const forgotPassword = () => {
  return {
    type: USER_ACTION_TYPES.RESET_PASSWORD
  }
};

export const recoverPasswordSuccessful = () => {
  return {
    type: USER_ACTION_TYPES.RESET_PASSWORD_SUCCESS
  }
};

export const recoverPasswordFailed = (message) => {
  return {
    type: USER_ACTION_TYPES.RESET_PASSWORD_FAIL,
    data: message
  }
};


//////////////////// THUNKS //////////////////////////////


export const setUserLoading = (isLoading) => {
    return ((dispatch)=>{
        if(isLoading){
            dispatch(setLoading());
        }else{
            dispatch(stopLoading());
        }
    })
};


export const loginUser = (id, password, history) => {
    return ((dispatch)=>{
        dispatch(login());
        userService.login(id, password)
            .then((user)=>{
                //console.log("history",history);
                //console.log("SAVING THIS USER",user);
                saveSession(dispatch, user, history)
            })
            .catch((err)=>{
                //console.log("ERROR=>",err);
                dispatch(loginFailed({message:err.message, code:err.code}));
            })
    })
};



export const resetPassword = (values) => {
  //try getting fcmToken if permission already granted
  return (dispatch) => {
    dispatch(forgotPassword());
    userService.resetPassword(values)
      .then(()=>{
        //console.log("RESPONSE",user);
        dispatch(recoverPasswordSuccessful())
      },(err)=>{
        const {data, statusText} = err.response||{status:-1,statusText:"Unknown Error",data:{}};
        dispatch(recoverPasswordFailed({message:data.message||statusText}));
      });
  }

};


const saveSession = (dispatch,user,history) =>{
    //set accesstoken in cookie
    //setCookie(DOMAIN_COOKIE_NAME,user.credentials.accessToken, 1 ,getDomain());
    sessionService.saveSession(user.credentials.accessToken)
        .then(()=>{
            sessionService.saveUser(user)
                .then(()=>{
                    dispatch(loginSuccessful(user,history));
                })
        });
};

// export const logoutUser = (from) => {
//     return sessionService.deleteUser()
//     .then(()=>{
//       sessionService.deleteSession()
//         .then(()=>{
//           //deleteCookie(DOMAIN_COOKIE_NAME,getDomain());
//           let dest = `/login${from ? `?r=${from}`:``}`;
//           window.location.replace(dest);
//         });
//     })
//     .catch(err => {
//       throw (err);
//     });
// };


// const logout = () => {
//   return new Promise(resolve => setTimeout(resolve, 1000));
// };

export const logoutUserFromDaaraService = () => {
  return async (dispatch) => {
      dispatch(daaraLogout());
      await sessionService.loadUser()
      .then(user => {
          console.log(user.credentials.daaraToken)
          fetch(`${process.env.REACT_APP_DAARA_ACCOUNT_SERVICE_URL}/logOut`, {
              method: 'GET',
              headers: new Headers({
              'Authorization': `Bearer ${user.credentials.daaraToken}`,
              'Content-Type': 'application/json'
              })
          }).then(
              response => dispatch(daaraLogoutSuccessful(response))
          )
          .catch(
              error => dispatch(daaraLogoutFailed(error))
          )
      })
      .catch(error => dispatch(daaraLogoutFailed(error)))
  }
};


export const logoutUser = (from) => {
  // console.log("Logging out");
  sessionService.deleteUser()
    .then(()=>{
      sessionService.deleteSession()
        .then(()=>{
          let destUrl = `${getAccountsDashboardUrl()}/logout`; //auth is dummy route, it actually goes to /dashboard/
          //console.log("navigating to:",destUrl);
          window.location.replace(destUrl+(from ? `?r=${from}`:``));
        });
    });
};
