import React from 'react';
import PropTypes from 'prop-types';

class TagGroupComponent extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      selectedTag:null
    }
  }

  selectTag = (tag) => {
    this.setState({
      selectedTag:tag
    });
    this.props.onTagSelected(tag);
  };

  render(){
    const {tags,title,subtitle} = this.props;

    return (
      <div style={{padding:"1rem", margin:"1rem 0"}} data-attr={title}>
        <h2 style={{marginBottom:"1rem"}}>{title}</h2>
        <p>{subtitle}</p>
        <div>
          {tags.map(tag=>(
            <Tag
              key={tag.id}
              id={tag.id}
              name={tag.name}
              selected={this.state.selectedTag && this.state.selectedTag.id === tag.id}
              onClick={()=>this.selectTag(tag)}/>
          ))}
        </div>
      </div>
    )
  }
}


const Tag = ({id,name,selected,onClick}) => (
  <button id={"tag-"+id} onClick={onClick} title={name} className={`btn ${selected ? 'primary':'transparent'}`} style={{marginLeft:"0"}}>
    {name}
  </button>
);


TagGroupComponent.propTypes  = {
  title:PropTypes.string,
  subtitle:PropTypes.string,
  tags: PropTypes.array.isRequired,
  preSelected: PropTypes.string,
  onTagSelected: PropTypes.func
};

Tag.propTypes = {
  id: PropTypes.number,
  name: PropTypes.string.isRequired,
  selected: PropTypes.bool
};

export default TagGroupComponent;