import React from 'react';
import ReactJoyride, { STATUS } from 'react-joyride';
import styled from '@emotion/styled';
import numberFormatter from 'number-formatter';
import affiliateService from '../services/affiliateService';
import LoadingScreen from '../common/LoadingScreen';
import MainContent from '../common/MainContent';
import PageHeader from '../common/PageHeader';
import PageBody from '../common/PageBody';
import Card, {CardHeader, CardHeaderWithButton, CardImage} from '../common/Card';
import TourSteps, {getTourConfigData, setTourConfigData} from '../utils/TourSteps';
import CopyToClipboardButton from "../utils/CopyToClipboardButton";
import {WEBSITE_URL} from "../config";
import StyleColors from "../utils/StyleColors";
import Modal from "../common/Modal";
import WalletComponent from "../components/WalletComponent";

class OverviewContainer extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      isLoading:false,
      showWalletModal: false,
      summary:[],
      overview:null,
      tourSteps: []
    }
  }

  componentDidMount(){
    this.setState({
      tourSteps: TourSteps.overviewTourSteps()
    });
    this.getOverviewData(true);
  }

  getOverviewData = (showLoading) => {
    if(showLoading)
      this.setLoading(true);

    affiliateService.get(affiliateService.ENDPOINTS.overview)
      .then(response=>{
        this.setState({
          isLoading:false,
          overview:response.data
        })
      })
  };

  setLoading = (isLoading) => {
    this.setState({
      isLoading:isLoading
    });
  };

  renderLoading = () => {
    return (
      <MainContent>
        <LoadingScreen/>
      </MainContent>
    )
  };

  handleJoyrideCallback = data => {
    const { status } = data;


    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      //update tour config
      let data = getTourConfigData();
      data.showOverviewTour = false;
      setTourConfigData(data);
    }
  };

  toggleWalletModal = () => {

    this.setState({
      showWalletModal: !this.state.showWalletModal
    },()=>{
      if(!this.state.showWalletModal)
        this.getOverviewData();
    })
  };

  // refreshPage = () => {
  //   window.location.reload();
  // };

  render(){

    const {overview, isLoading, tourSteps, showWalletModal} = this.state;
    const {user} = this.props;

    let affiliateLink = `${WEBSITE_URL}/refer?aid=${user.affiliateCode}`;

    if(isLoading || !overview) return this.renderLoading();

    return (
      <MainContent>
        <PageHeader title="Overview"/>
        <PageBody>
          {showWalletModal &&
            <WalletModal onClose={this.toggleWalletModal}/>
          }
          <RowStyle className="link-row">
            <Card customStyles={{height:"auto"}}>
              <CardHeaderWithButton
                title="Your Affiliate Link"
                button={(<CopyToClipboardButton prompt="Copy Affiliate Link" value={affiliateLink}/>)}/>
              <AffiliateLink href={affiliateLink} target="_blank">{affiliateLink}</AffiliateLink>
              <AffiliateLinkDescription>
                Share the link above with friends, family etc and receive a {user.affiliateInfo.commissionRate ? user.affiliateInfo.commissionRate : "7.5"}% commission off whatever product the person buys.
                The more people use your link, the more you earn so start sharing now
              </AffiliateLinkDescription>
            </Card>
          </RowStyle>
          <RowStyle className="sales-row">
            <ColumnStyle>
              <Card type="small">
                <CardHeader title="Total Earnings"/>
                <CardImage image={<i className="fa fa-money fa-2x"/>} type="small"/>
                <p>{`${numberFormatter("₦#,##0.00", overview.total_earnings)}`}</p>
              </Card>
              <Card type="small">
                <CardHeader title="Total Visitors"/>
                <CardImage image={<i className="fa fa-mouse-pointer fa-2x"/>} type="small"/>
                <p>{overview.total_clicks}</p>
              </Card>
            </ColumnStyle>
            <ColumnStyle>
              <Card type="small">
                <CardHeader title="Total Sign Ups"/>
                <CardImage image={<i className="fa fa-user-plus fa-2x"/>} type="small"/>
                <p>{overview.total_signups}</p>
              </Card>
              <Card type="small">
                <CardHeader title="Total Purchases"/>
                <CardImage image={<i className="fa fa-shopping-cart fa-2x"/>} type="small"/>
                <p>{overview.total_purchases}</p>
              </Card>
            </ColumnStyle>
            <WalletComponent type="big"/>
          </RowStyle>
          <RowStyle className="product-row">
            <Card customStyles={{height:"auto"}}>
              <CardHeader title="Products"/>
              <RowStyle>
                <ProductItem
                  name="Red Beryl Plan"
                  price="3,500"
                  description="Affordable Health Insurance for medical expenses up to ₦1.2 million per year."
                  learnMoreUrl={WEBSITE_URL}
                  soldCount={getProductSoldCount(overview.product_purchases,"RB")}
                />
                <ProductItem
                  name="Alexandrite Plan"
                  price="6,000"
                  description="Health Insurance for medical expenses up to ₦1.8million per year with the best hospitals near you."
                  learnMoreUrl={WEBSITE_URL}
                  soldCount={getProductSoldCount(overview.product_purchases,"ALX")}
                />
                <ProductItem
                  name="Pre-existing Condition Plan"
                  price="8,000"
                  description="Instant Healthcare cover for Pre-existing conditions. Treatment and drugs for Hypertension, Diabetes and Arthritis."
                  learnMoreUrl={WEBSITE_URL+"/pre-existing-condition"}
                  soldCount={getProductSoldCount(overview.product_purchases,"PEC")}
                />
              </RowStyle>
            </Card>
          </RowStyle>
        </PageBody>
        <ReactJoyride
          run={getTourConfigData().showOverviewTour || false}
          steps={tourSteps}
          continuous
          callback={this.handleJoyrideCallback}
          showSkipButton
          showProgress
          styles={{
            options: TourSteps.styleOptions()
          }}
        />
      </MainContent>
    )
  }
}

const getProductSoldCount = (products, productCode) => {
  return products.filter(p=>p.code === productCode)[0].count || 0;
};

const WalletModal = ({onClose}) => (
  <Modal title="Wallet" onClose={onClose}>
    <WalletComponent/>
  </Modal>
);

let ProductItem = ({className,name, price, description, learnMoreUrl, soldCount}) => {
  return (
    <div className={className}>
      <div className="title-wrapper">
        <h3>{name}</h3>
        <div className="sold-count">
          <h4>{soldCount}</h4>
          <p>sold</p>
        </div>
      </div>
      <p className="small">Starting from</p>
      <p>
        <span className="price">₦{price}</span>/month
      </p>
      <p>{description}</p>
      <a href={learnMoreUrl}>learn more</a>
    </div>
  )
};

ProductItem = styled(ProductItem)`
  margin-right: 1rem;
  
  .title-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 1rem;
    
    .sold-count {
      border: 2px solid ${StyleColors.brand03};
      color: ${StyleColors.brand03};
      border-radius: 1rem;
      text-align: center;
      padding: 0.5rem 1rem;
      margin-right: 1rem;
      margin-left: 1rem;
      
      h3 {
        
      }
      
      p {
        font-size: 1rem;
        margin-bottom: 0;
      }
    }
  }
  
  p.small {
    margin-top: 0.5rem;
    font-size: 1.4rem;
    font-weight: 400;   
    margin-bottom:0.5rem; 
  }
  
  .price {
    font-weight: 900;
    font-size: 2.6rem;
  }
  
  a, p {
    font-size: 1.6rem;
    margin-bottom: 1rem;
    font-weight: normal;
  }
  
  a {
    color: ${StyleColors.text02};
  }
  
  @media screen and (max-width: 768px) {
    padding-bottom: 1rem;
    margin: 1rem 0;
    border-bottom: 1px solid #ccc;
  }
`;


const RowStyle = styled.div`
  display: flex;
  width:100%;
  justify-content:space-between;
  
  & > div {
    flex: 1;
  }
  
  @media screen and (max-width: 768px) {
    & {
      flex-direction: column;
    }
`;

const ColumnStyle = styled.div`
  display: flex;
  flex-direction: column;
`;

const AffiliateLink = styled.a`
    margin-top: 1rem;
    font-size: 2.2rem;
    font-weight: 800;
    color: #1b496b;
    text-decoration: none;
    word-break: break-all;
`;

const AffiliateLinkDescription = styled.p`
  font-size: 1.6rem;
  margin-top: 1rem;
  font-weight: normal;
`;

export default OverviewContainer;