
const StyleColors = {
  colorBlack: "#212121",
  colorWhite: "#FFFFFA",
  colorBgBlue: "#89CBF0",
  colorMainblue: "#094063",
  colorMidblue: "#107BC0",
  colorMaingrey: "#f9f9f9",


  text01: "#212121",
  text02: "#4F4F4F",
  text03: "#333333",
  text04: "#4B4B4B",

  ui01: "#FFFFFF",
  ui02: "#FDFEFE",
  ui03: "#F2F2F2",
  ui04: "#E6F0F9",
  ui05: "#E5E5E5",
  ui06: "#C4C4C4",
  ui07: "#FBFBFB",
  ui08: "#F9FAFC",
  ui09: "#819FB1",
  ui10: "#FBFCFD",
  ui11: "#EEE",
  ui12: "#F8F8F8",

  brand01: "#094063",
  brand02: "#107BC0",
  brand03: "#5199D3",
  brand04: "#89CBF0",
  brand05: "#C0E5E5",

  brand01hover: "#175171",
  brand01focus: "#093B5A",
  brand01disabled: "#8FA8B8",

  danger: "#AB0404",
  dangerhover: "#B21A1A",
  dangerfocus: "#9C0404",
  dangerdisabled: "#D88C8C",

  success: "#219653"

};

export default StyleColors;
