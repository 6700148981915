/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import PropTypes from 'prop-types';
import UserProfileForm from "./forms/UserProfileForm";

const user_profile = css`
  .dashboard_profile {
      background-color: #FFFFFF;
      padding: 2rem;
      border-radius: 4px;
      //flex-wrap: wrap;
      // border: 1px solid rgba(9, 64, 99, 0.1);
      margin: 2rem;
      justify-content: space-between;
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
  
      form {
      display: flex;
      flex-direction: column;
      }
  
      .profile_input {
      border: 1px solid #E5E5E5;
      border-radius: 4px;
      padding: 1rem 1.2rem;
      width: 30rem;
      margin-top: 1rem;
      }
  
      .profile_input_button {
      width: 150px;
      color: white;
      background-color: #094063;
      border-radius: 4px;
      font-size: 15px;
      font-weight: 600;
      padding: 10px 10px;
      text-decoration: none;
      margin-top: 2rem;
      }
  }

  .dashboard_profile_details, .dashboard_profile_form {
    .user-profile-header,
    .user-profile-affiliate-id,
    .user-form-header,
    .user-details-form {
      margin-bottom: 20px;
    }
  
    .user-profile-affiliate-id,
    .user-profile-email {
      h3 {
        margin-bottom: 10px;
      }
    }
  
    .user-profile-header {
      font-size: 1.6rem;
      color: #212121;
      font-weight: 800;
    }
  
    .profile-detail-uneditable {
      color: #8FA8B8;
    }
  
    .profile-label {
      font-size: 1.2rem;
      font-weight: 300;
    }
  
    .profile-text {
      font-size: 1.6rem;
      font-weight: bold;
      margin-top: 1rem;
    }
  }

  .dashboard_profile_form {
    .form-wrapper {
      display: flex;
      // flex-direction: column;
    }
  
    .form-1 {
      margin-right: 2rem;
    }
  
    .form-1,
    .form-2 {
      display: flex;
      flex-direction: column;
      
  
      .form-group {
        display: flex;
        flex-direction: column;
        margin-bottom: 1rem;
      }
  
      label {
        font-size: 1.4rem;
        font-weight: 600;
        color: #4F4F4F;
        display: block;
      }
  
      input::placeholder {
        font-size: 1.5rem;
        font-weight: 800;
        color: #E5E5E5;
      }
    }
  }

  @media screen and (max-width: 768px) {
    .dashboard_profile {
      flex-direction: column;
      // padding: 0;
  
      .profile_input {
          width: 100%;
      }

      .form-1 {
        margin: 0;
      }
  
      .form-wrapper {
          flex-direction: column;
      }
  
      img {
          align-self: center;
          max-width: 100%;
          height: auto;
      }
    }
  }
`
const UserProfileComponent = ({user,onUpdateProfile}) => (
    <div css={user_profile}>
        <div className="dashboard_profile dashboard_profile_details">
            <h2 className="user-profile-header">This section cannot be edited or changed</h2>
            <div className="user-profile-affiliate-id">
                <h3 className="profile-detail-uneditable profile-label">Affiliate ID</h3>
                <p className="profile-detail-uneditable profile-text">{user.affiliateCode}</p>
            </div>
            <div className="user-profile-email">
                <h3 className="profile-detail-uneditable profile-label">Registered Email</h3>
                <p className="profile-detail-uneditable profile-text">{user.emailAddress}</p>
            </div>
        </div>
        <div className="dashboard_profile dashboard_profile_form">
            <h2 className="user-form-header">Personal Information</h2>
            <UserProfileForm user={user} onSubmit={onUpdateProfile}/>
        </div>
    </div>
);

UserProfileComponent.propTypes = {
    user: PropTypes.object,
    onUpdateProfile: PropTypes.func
};

export default UserProfileComponent;