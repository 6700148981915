/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import { Route, Link } from 'react-router-dom';

const submenuItemsHide = css`
    display: none;
`;

const submenuItemsShow = css`
    display: flex;
    flex-direction: column;
    height: auto;

    li {
        height: 3em;
        padding: 1em 0 0 3em;
        align-items: center;
        cursor: pointer;

        a {
            text-decoration: none;
            color: #FFFFFF;
            font-style: normal;
            font-weight: 300;
        }

        .fa {
            margin-right: 1rem;
            color: #094063;
        }
    }
`;

const menu_active = css`
    border-left: 5px solid #094063;
    background: rgba(9, 64, 99, 0.2);
`;

const display_menu_active = (match, path) => {
    return match && path.length > 0 && menu_active
};

const SubMenuItemComponent = (props) => (
    props.subMenuItems.length > 0 && 
        <ul id="sub_menu_items" css={props.displaySubMenuItems ? submenuItemsShow : submenuItemsHide}>
            {props.subMenuItems.map((subMenuItem, i) => (
                <Route key={`sub-menu-item-${i+1}`} path={subMenuItem.path} exact children={({match}) => (
                    <li key={`sub-menu-item-${i+1}`} css={display_menu_active(match, subMenuItem.path)}>
                        <Link
                            to={subMenuItem.path}
                            className={subMenuItem.title.toLowerCase()}
                            >{subMenuItem.title}
                        </Link>
                    </li>
                )}/>
            ))}
        </ul>
);

export default SubMenuItemComponent;