import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import { configureStore } from './store/configureStore';
import defaultStore from './store/defaultStore';
import { sessionService } from 'redux-react-session';
import {unregister} from './registerServiceWorker';
import './index.css';
import App from './App';

const store = configureStore(defaultStore);

sessionService.initSessionService(store,{driver:"LOCALSTORAGE"});

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);
unregister();
