const defaultStore = {
  user: {
    isFetching: true,
    isLoggedIn: false,
    invalidated: false,
    alert: "",
    error: null,
    info:{
      firstName:"",
      lastName:"",
      emailAddress:"",
      phoneNumber:"",
      affiliateCode:"",
      affiliateInfo: {
        commissionRate: 0,
        instagramHandle: "",
        twitterHandle: "",
        type: "",
        website: ""
      },
      bankInfo:{
        bankId:0,
        accountName:"",
        accountNumber:"",
        bankName:""
      }
    },
    preferences:{
      allowNotifications:false
    },
    credentials:{
      accessToken:""
    }
  },
  display: {
    showSideBar: false
  }
};

export default defaultStore;