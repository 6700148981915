import React from 'react';
import {withRouter} from 'react-router-dom';
import queryString from 'querystring';
import {DISPLAY_ACTION_TYPES} from '../actions/actionTypes';
import SidebarContainer from './SidebarContainer';
import AppRoutes from "../routes/AppRoutes";
import {getTourConfigData} from '../utils/TourSteps';
import LoadingScreen from "../common/LoadingScreen";


class DashboardContainer extends React.Component {

  constructor(props) {
    super(props);

    const {dispatch, location} = props;

    this.state = {
      referrer: queryString.parse(location.search.substr(1)).r || window.location.href
    };

    props.history.listen(() => {
      dispatch({type: DISPLAY_ACTION_TYPES.HIDE_SIDEBAR});
    });
  }


  render() {
    const {user} = this.props;

    if(getTourConfigData() && getTourConfigData().showWelcomeTour) {
      window.location.href = "/welcome";
      return <LoadingScreen/>;
    }else{
      return (
        <React.Fragment>
          <SidebarContainer user={user}/>
          <AppRoutes authenticated={true} user={user}/>
        </React.Fragment>
      );
    }


    //}
    // const referrer = this.state.referrer;
    // if (checked && !authenticated) return (<Redirect to={`/login${referrer ? `?r=${referrer}` : `` }`}/>);
  }
}

// const mapStateToProps = ({session}) => ({
//   checked: session.checked,
//   authenticated: session.authenticated,
//   user: session.user || null
// });

export default withRouter((DashboardContainer));