import React from 'react';
import PropTypes from 'prop-types';
import numberFormatter from 'number-formatter';
import affiliateService from '../services/affiliateService';
import LoadingScreen from "../common/LoadingScreen";
import CashOutForm from "./forms/CashOutForm";
import NotificationBanner from "../common/NotificationBanner";
import Card, {CardHeader, CardImage} from "../common/Card";
import PENDING_PAYMENT_IMG from '../img/pending-pay.svg';
import Modal from "../common/Modal";

class WalletComponent extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      isLoading: true,
      balance: 0,
      cashOutAmount: 0,
      cashOutResponse: null,
      toggleWalletModal: false
    };
  }

  renderLoading = () => {
    return (
      <div style={{ position:"relative",width:"100%",minWidth:"10rem"}}>
        <LoadingScreen/>
      </div>
    )
  };

  componentDidMount(){
    this.getBalance();
  }

  getBalance = () => {
    this.setState({
      isLoading: true
    });
    affiliateService.get(affiliateService.ENDPOINTS.wallet.balance)
      .then(response=>{
        this.setState({
          isLoading:false,
          balance:{
            total: response.data.total_balance,
            available: response.data.available_balance
          }
        })
      })
  };

  onCashOut = ({amount}) => {
    this.setState({
      isLoading:true
    });
    affiliateService.post(affiliateService.ENDPOINTS.wallet.cashOut,{amount})
      .then(response=>{
        this.setState({
          isLoading:false,
          cashOutResponse:{
            status:"success",
            message:`Cash-out Successful. You should receive your money within 3-5 working days.`
          },
          balance: response.data.available_balance
        },()=>{
          setTimeout(this.getBalance(),1000);
        });
      })
      .catch(e=>{
        this.setState({
          isLoading: false,
          cashOutResponse:{
            status:"error",
            message: e.message
          }
        })
      });
  };

  toggleWalletModal = () => {
    console.log("toggle modal");
    this.setState({
      toggleWalletModal: !this.state.toggleWalletModal
    })
  };


  render(){

    const {isLoading, balance, cashOutResponse, toggleWalletModal} = this.state;

    const {type} = this.props;

    if(isLoading) return this.renderLoading();

    if(type && type === "big") {
      return (
        <React.Fragment>
          {toggleWalletModal &&
            <WalletModal onClose={this.toggleWalletModal} response={cashOutResponse} onCashOut={this.onCashOut} balance={balance}/>
          }
          <Card type="big" customStyles={{justifyContent:"center"}}>
            <CardImage image={<img src={PENDING_PAYMENT_IMG} alt="Available Balance"/>} type="big"/>
            <CardHeader title="Available Balance"/>
            <p>
              {`${numberFormatter("₦#,##0.00",(balance.available))}`}
              <span data-tooltip="Commissions made are only available to cash-out three(3) days after the sale is made." className="tooltip"><i className="fa fa-info-circle"/></span>
            </p>
            <button className="btn primary cash-out" onClick={this.toggleWalletModal}>Cash-Out</button>
          </Card>
        </React.Fragment>
      )
    }

    if(type && type === "button"){

      return (
        <React.Fragment>
          {toggleWalletModal &&
            <WalletModal onClose={this.toggleWalletModal} response={cashOutResponse} onCashOut={this.onCashOut} balance={balance}/>
          }
          <button className="btn primary" onClick={this.toggleWalletModal} style={{width:"auto"}}>My Wallet</button>
        </React.Fragment>
      );

    }

    // return (
    //   <div>
    //     {cashOutResponse &&
    //       <NotificationBanner
    //         title={cashOutResponse.status.toUpperCase()}
    //         message={cashOutResponse.message}
    //         type={cashOutResponse.status}/>
    //     }
    //     <Card type="small">
    //       <CardHeader title="Balance"/>
    //       <p>Available Balance: &#8358;{`${numberFormatter("#,##0.00",balance.available)}`}</p>
    //       <p>Total Balance: &#8358;{`${numberFormatter("#,##0.00",balance.total)}`}</p>
    //       <CardImage image={<i className="fa fa-2x fa-money"/>} type="small"/>
    //     </Card>
    //     <CashOutForm onSubmit={this.onCashOut}/>
    //   </div>
    // )
  }
}

export default WalletComponent;

const WalletModal = ({response, balance, onCashOut, onClose}) =>  (
  <Modal title="My Wallet" onClose={onClose}>
    <React.Fragment>
      {response &&
      <NotificationBanner
        title={response.status.toUpperCase()}
        message={response.message}
        type={response.status}/>
      }
      <Card type="small" customStyles={{width:"100%"}}>
        <CardHeader title="Balance"/>
        <p style={{fontSize:"1.5rem"}}>Available Balance: &#8358;{`${numberFormatter("#,##0.00",balance.available)}`}</p>
        <p style={{fontSize:"1rem",fontWeight:"normal"}}>
          Total Balance: &#8358;{`${numberFormatter("#,##0.00",balance.total)}`}
          <span data-tooltip="Commissions made are only available to cash-out three(3) days after the sale is made." style={{fontSize:"1rem", marginLeft:"0.5rem"}}><i className="fa fa-info-circle"/></span>
        </p>
        <CardImage image={<i className="fa fa-2x fa-money"/>} type="small"/>
      </Card>
      <CashOutForm onSubmit={onCashOut}/>
    </React.Fragment>
  </Modal>
);

WalletComponent.propTypes = {
  type: PropTypes.string.isRequired
};