/** @jsx jsx */
import { css, jsx } from "@emotion/core";

const rightStyle = css`
    width: 100%;
    position: relative;
    overflow-y: scroll;
    min-width: 30rem;
`;

const MainContent = (props) => (
  <div id="right" css={rightStyle}>
    {props.children}
  </div>
);

export default MainContent