import React from 'react';
import styled from '@emotion/styled';
import StyleColors from '../utils/StyleColors';

const PageBodyStyle = styled.div`
    background-color: ${StyleColors.ui12};
    height: 100%;
    padding: 2rem;
    flex-grow: 1;
    overflow-y: auto;
    margin-top:80px;
    /**height:100%;**/
`;

const PageBody = (props) => (
  <PageBodyStyle>
    {props.children}
  </PageBodyStyle>
);

export default PageBody