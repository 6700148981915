import React from 'react';
import styled from '@emotion/styled';
import Card from "./Card";
import StyleColors from '../utils/StyleColors';

const AccordionHeader = styled.div`
  padding: 1rem;
  cursor:pointer;
  display:flex;
  color: ${StyleColors.brand02};
  justify-content:space-between;
  &:after {
    content: ${props=>props.open ? "\"\f078\"" : "\"\f054\""};
    margin-right: 1rem;    
  }  
`;

const AccordionBody = styled.div`
  padding: 1rem;
  font-size: 1.5rem;
  line-height: 2.5rem;
  border-top: 2px solid #f8f8f8;  
  display: ${props=> props.open ? 'block' : 'none'}
`;

class Accordion extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      open:props.open || false
    }
  }

  toggleOpen = () => {
    this.setState({
      open: !this.state.open
    })
  };

  render(){
    const {open} = this.state;
    const {title, content} = this.props;
    return (
      <Card customStyles={{margin:"1rem 0",height:"auto",padding:"1rem"}}>
        <AccordionHeader open={open} onClick={this.toggleOpen}>{title}</AccordionHeader>
        <AccordionBody open={open}>{content}</AccordionBody>
      </Card>
    )
  }
}

export default Accordion;