import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import MainContent from '../common/MainContent';
import PageHeader from '../common/PageHeader';
import PageBody from '../common/PageBody';

class Notification extends Component {
  render() {
    return (
      <MainContent>
        <PageHeader title="Notifications"/>
        <PageBody>
          <div className="notification-detail-card">
            <div className="notification-detail">
              <h2>New fights</h2>
              <p>
                The day before this was an interesing day, A fight broke out and two people where found injured and battered in the board room
                Vestibulum eget convallis enim.
                Curabitur in ligula et elit laoreet pulvinar. Pellentesque a malesuada turpis. Curabitur tincidunt dictum lectus ut scelerisque. Cras vitae justo vitae erat congue dapibus.
              </p>
              <Link to={"/notifications"}className="notification-detail-btn">Back</Link>
            </div>
          </div>
        </PageBody>
      </MainContent>
    );
  }
}

export default Notification;