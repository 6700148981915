import React from 'react';
import {mapUser} from "../services/userService";
import {sessionService} from 'redux-react-session';
import affiliateService from '../services/affiliateService';
import NotificationBanner from '../common/NotificationBanner';
import LoadingScreen from '../common/LoadingScreen';
import PageHeader from '../common/PageHeader';
import PageBody from '../common/PageBody';
import MainContent from '../common/MainContent';
import UserProfileComponent from "../components/UserProfileComponent";


class UserProfileContainer extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading:false,
      notification: null
    }
  }


  renderLoading() {
    return (
      <MainContent>
        <PageBody>
          <LoadingScreen/>
        </PageBody>
      </MainContent>
    )
  };

  onUpdateProfile = (values) => {

    this.setNotification(null);
    this.setLoading(true);

    let json = {
      user_info:{
        first_name: values.first_name,
        last_name: values.last_name,
        phone_number: values.phone_number,
        email_address: this.props.user.emailAddress,
        referral_code: this.props.user.affiliateCode,
        twitter_handle: values.twitter_handle,
        instagram_handle: values.instagram_handle,
        website: values.website
      },
      bank_info: {
        bank_id:values.bank_id,
        account_name:values.account_name,
        account_number: values.account_number
      }
    };
    affiliateService.put(affiliateService.ENDPOINTS.profile,json)
      .then(response=>{
        sessionService.saveUser(mapUser({data:{...json}}))
          .then(()=>{
            this.setLoading(false);
            this.setNotification({type:"success",message:response.data.message})
          });
      })
      .catch(e=>{
        this.setLoading(false);
        this.setNotification({type:"error",message:e.message});
      })

  };

  setNotification = (notification) => {
    this.setState({
      notification
    })
  };

  setLoading = (isLoading) => {
    this.setState({
      isLoading
    })
  };

  goToChangePwdButton = () => {
    const { history } = this.props;
    history.push('/change-password');
  }

  render() {
    const {user} = this.props;

    const {notification, isLoading} = this.state;

    if(isLoading) return this.renderLoading();

    return (
      <MainContent>
        <PageHeader 
          title={`My Profile`} 
          showButton 
          onButtonClick={this.goToChangePwdButton}
          buttonTitle="Change Password"/>
        <PageBody>
          {notification &&
          <NotificationBanner
            title={notification.type.toUpperCase()}
            message={notification.message}
            type={notification.type}/>
          }

          <UserProfileComponent user={user} onUpdateProfile={this.onUpdateProfile}/>
        </PageBody>
      </MainContent>
    );
  }
}


export default UserProfileContainer;