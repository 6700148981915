import React from 'react';
import PropTypes from 'prop-types';
import {Redirect} from 'react-router';
import { Switch, Route, withRouter } from "react-router-dom";
import PageNotFoundComponent from '../components/PageNotFoundComponent';
import UserProfileContainer from "../containers/UserProfileContainer";
import Transactions from '../containers/TransactionsContainer';
import Report from './ReportRoutes';
import OverviewContainer from "../containers/OverviewContainer";
import AssetsContainer from "../containers/MarketingContainer";
import NotificationsList from "../components/NotificationsList";
import Notification from "../components/Notification";
import PropsRoute from "../utils/PropsRoute";
import FaqContainer from "../containers/FaqContainer";
import ChangePasswordComponent from "../components/ChangePasswordComponent";

const AppRoutes = ({authenticated, user}) => (
  <Switch>
    <PropsRoute component={UserProfileContainer} path="/profile"
                  authenticated={authenticated} user={user} exact/>
    <PropsRoute component={OverviewContainer} path="/overview"
                  authenticated={authenticated} user={user} exact/>
    <PropsRoute component={AssetsContainer} path="/marketing-materials"
                  authenticated={authenticated} user={user} exact/>
    <PropsRoute path='/notifications' component={NotificationsList} authenticated={authenticated} user={user} exact/>
    <PropsRoute path='/notification-detail' component={Notification} authenticated={authenticated} user={user} exact/>
    <PropsRoute path='/transactions' component={Transactions} authenticated={authenticated} user={user} exact/>
    <PropsRoute component={Report} path="/reports" authenticated={authenticated} user={user}/>
    <PropsRoute path='/change-password' component={ChangePasswordComponent} authenticated={authenticated} user={user} exact/>
    <PropsRoute component={FaqContainer} path="/help" authenticated={authenticated} user={user}/>
    <Route path='/' render={props=><Redirect to="/overview"/>}/>
    <Route component={PageNotFoundComponent}/>
  </Switch>
);

AppRoutes.propTypes = {
  authenticated: PropTypes.bool,
  user: PropTypes.object
};

export default withRouter(AppRoutes);