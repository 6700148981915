/** @jsx jsx */
import React from 'react';
import ReactJoyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import { css, jsx } from "@emotion/core";
import {WEBSITE_URL} from "../config";
import affiliateService from '../services/affiliateService';
import LoadingScreen from '../common/LoadingScreen';
import MainContent from '../common/MainContent';
import PageHeader from '../common/PageHeader';
import PageBody from '../common/PageBody';
import TagGroupComponent from "../components/TagGroupComponent";
import CopyToClipboardButton from "../utils/CopyToClipboardButton";
import TourSteps, {getTourConfigData, setTourConfigData} from '../utils/TourSteps';

const two_column_layout = css`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .dashboard_card {
    background-color: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(81, 153, 211, 0.04);
    border-radius: 4px;
    color: #094063;
    padding: 1rem;
    flex: 0.5;

    hr {
      opacity: 0.2;
      border: 2px solid #E5E5E5;
      margin: 2rem 0;
    }
  }

  .tools_card {
    width: 49%;
  }

  .product_options {
    margin-right: 2rem;
  }

  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;

    .tools_card {
      width: 100%;
      height: fit-content;
      margin-bottom: 2rem;
    }
  }
`;

class AssetsContainer extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      isLoading:false,
      materials:[],
      images: [],
      selectedMaterial:null,
      selectedImage:null,
      run: false,
      tourSteps:[],
      stepIndex: 0
    }
  }

  componentDidMount(){
    this.setState({
      run: getTourConfigData().showAssetsTour || false,
      tourSteps: TourSteps.assetsTourSteps(),
    });

    this.setLoading(true);

    affiliateService.get(affiliateService.ENDPOINTS.materials)
      .then(response=>{
        this.setState({
          isLoading:false,
          materials:response.data
        })
      })
  }

  setLoading = (isLoading) => {
    this.setState({
      isLoading
    })
  };
  
  onMaterialSelected = (material) => {
    const {stepIndex} = this.state;

    this.setState({
      selectedMaterial:material,
      images:[],
      selectedImage:null,
      stepIndex: stepIndex === 0 ? 1 : stepIndex
    });

    affiliateService.get(affiliateService.ENDPOINTS.images(material.id))
      .then(response=>{
        this.setState({
          images:response.data.map(image=>{
            image.name = image.dimension;
            return image;
          })
        })
      })
  };

  onImageSelected = (image) => {
    const {stepIndex} = this.state;
    this.setState({
      selectedImage:image,
      stepIndex: stepIndex === 2 ? 3 : stepIndex
    })
  };

  renderLoading = () => {
    return (
      <MainContent>
        <LoadingScreen/>
      </MainContent>
    )
  };

  handleJoyrideCallback = data => {
    const { action, index, type, status } = data;

    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      // Need to set our running state to false, so we can restart if we click start again.
      this.setState({ run: false, stepIndex: 0 });
      //update tour config
      let data = getTourConfigData();
      data.showAssetsTour = false;
      setTourConfigData(data);
    }

    else if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      
      const stepIndex = index + (action === ACTIONS.PREV ? -1 : 1);
      this.setState({stepIndex});
    }
  };

  render (){
    const {isLoading, materials, images, selectedImage, selectedMaterial, tourSteps, stepIndex, run} = this.state;
    const {user} = this.props;

    if(isLoading || materials.length === 0) return this.renderLoading();

    return (
      <MainContent>
        <PageHeader title="Marketing Materials"/>
        <PageBody>
          <div css={two_column_layout}>
            <div className="dashboard_card tools_card product_options">
              <TagGroupComponent
                tags={materials}
                title="Marketing Options"
                subtitle="Select a target you want to market to."
                onTagSelected={this.onMaterialSelected}/>
              {selectedMaterial &&
                <div style={{margin:"1rem 0",padding:"1rem"}} data-attr="product url container">
                  <div style={{display:"flex",alignItems:"center",justifyContent:"space-between"}}>
                    <h2>Marketing Link <span data-tooltip="Copy and Paste this link anywhere (whatsapp, facebook, twitter etc.) to market the product. Purchases made through this link will be attributed to you."><i className="fa fa-info-circle"/></span></h2>
                    <CopyToClipboardButton prompt="Copy Marketing Link" value={`${generateMaterialUrl(selectedMaterial.id, selectedMaterial.url, user.affiliateCode)}`}/>
                  </div>
                  <div className="code-layout">
                    {`${generateMaterialUrl( selectedMaterial.id, selectedMaterial.url, user.affiliateCode)}`}
                  </div>
                </div>
              }
              <hr/>
              {selectedMaterial ? images.length > 0 ? (
                  <TagGroupComponent
                    tags={images}
                    title="Banner Sizes"
                    subtitle="Select the size of the image you want to use in marketing."
                    onTagSelected={this.onImageSelected}/>
                ) : (
                  <div style={{padding:"2rem",position:"relative",marginTop:"10rem",width:"50%"}}><LoadingScreen/></div>
                )
               : (
                  <br/>
              )}

              {selectedImage &&
                <div data-attr="Banner Embed Code" style={{padding:"1rem",marginTop:"1rem"}}>
                  <div style={{margin:"1rem 0"}}>
                    <div style={{display:"flex",alignItems:"center",justifyContent:"space-between"}}>
                      <h2>Banner Embed Code <span data-tooltip="Copy and Paste this code on a webpage or blog post to show the product ad. Purchases made by clicking the image will be attributed to you."><i className="fa fa-info-circle"/></span></h2>
                      <CopyToClipboardButton
                        prompt="Copy Banner Code"
                        value={generateBannerCode(selectedMaterial.id, selectedMaterial.url, selectedMaterial.name, user.affiliateCode, selectedImage.id, selectedImage.url)}/>
                    </div>
                    <div className="code-layout">
                      {generateBannerCode(selectedMaterial.id, selectedMaterial.url, selectedMaterial.name, user.affiliateCode, selectedImage.id, selectedImage.url)}
                    </div>
                  </div>
                </div>
              }
            </div>

            <div className="dashboard_card tools_card preview">
              {selectedImage &&
                <div style={{padding:"1rem"}}>
                  <h2>Preview:</h2>
                  <img src={selectedImage.url} alt="Assets" style={{maxWidth:"100%",margin:"1rem 0"}}/>
                </div>
              }
            </div>
          </div>

        </PageBody>
        <ReactJoyride
          run={run}
          steps={tourSteps}
          stepIndex={stepIndex}
          continuous
          showSkipButton
          showProgress
          callback={this.handleJoyrideCallback}
          styles={{
            options: TourSteps.styleOptions()
          }}
        />
      </MainContent>
    )
  }
}

function generateMaterialUrl(materialId,materialUrl,affiliateCode){
  return `${WEBSITE_URL}${materialUrl}?aid=${affiliateCode}&pid=${materialId}`;
}

function generateBannerCode(materialId, materialUrl,materialName, affiliateCode,affiliateResourceId,affiliateResourceUrl){
  return `
        <a 
            href="${`${generateMaterialUrl(materialId,materialUrl, affiliateCode)}&rid=${affiliateResourceId}`}" target="_blank">
                <img src="${affiliateResourceUrl}" alt="${materialName}"/>
        </a>
      `
}

export default AssetsContainer;