/** @jsx jsx */
import React from 'react';
import { css, jsx } from "@emotion/core";
import { Link, Route } from 'react-router-dom';
import SubMenuItem from './SubMenuItemComponent';

const dashboard__menu = css`
    height: 5rem;
    display: flex;
    padding-left: 2rem;
    align-items: center;
  
    a, p {
        text-decoration: none;
        color: #FFFFFF;
        font-style: normal;
        font-weight: 300;
        margin-right: 1rem;
        cursor: pointer;
    }
  
    .fa {
      margin-right: 1rem;
      color: #FFFFFF;
    }
`;

const menu_active = css`
    border-left: 5px solid #094063;
    background: rgba(9, 64, 99, 0.2);
`;

class SideBarMenuItem extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            displaySubMenuItems: false
        }
    }
    
    toggleSubMenuItems = () => {
        this.setState({
            displaySubMenuItems: !this.state.displaySubMenuItems
        })
    }

    render() {
        const { displaySubMenuItems } = this.state
        const { path, icon, title, subMenuItems} = this.props;

        const display_menu_active = (match, path) => {
            return match && path.length > 0 && menu_active
        }

        return (
            <Route path={path} exact children={({match}) => (
                <div>
                    <li 
                        css={[dashboard__menu, display_menu_active(match, path)]}  
                        onClick={this.toggleSubMenuItems}>

                        <i className={icon} aria-hidden="true"/>
                        {
                            path.length === 0 
                            ? 
                                <p>{title}</p>
                            :
                                <Link 
                                    to={path}
                                >{title}</Link>
                        }
                        {
                            subMenuItems.length > 0 &&
                                <i className={`fa fa-caret-${displaySubMenuItems  ? "up" : "down"}`}/>
                        }
                    </li>

                    <SubMenuItem
                        displaySubMenuItems={displaySubMenuItems}
                        subMenuItems={subMenuItems}
                    />
                </div>
            )}/>
        )
    }
}

export default (SideBarMenuItem);