import React from 'react';
import Proptypes from 'prop-types';
import SimplePageHeader from '../common/SimplePageHeader';
import MainContent from '../common/MainContent';
import PageBody from '../common/PageBody';
import Modal from "../common/Modal";
import LOGO from '../img/logo.svg';

const NotAuthorizedComponent= ({redirectUrl, message}) => {
  return (
    <MainContent>
      <SimplePageHeader title="" logo={LOGO}/>
      <PageBody>
        <Modal title="Notification" onClose={()=>window.location.replace(redirectUrl)} open={true}>
          <div style={{display:"flex",flexDirection: "column",padding:"1rem"}}>
            <p style={{fontSize:"2rem"}}>{message || "Sorry, an error seems to have occurred."}</p>
            <button className="primary_button" onClick={()=>window.location.replace(redirectUrl)}>
              Click here to go back.
            </button>
          </div>
        </Modal>
      </PageBody>
    </MainContent>
  )
};

NotAuthorizedComponent.propTypes = {
  redirectUrl: Proptypes.string,
  message: Proptypes.string
};

export default NotAuthorizedComponent;