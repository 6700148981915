/** @jsx jsx */
import {css,jsx} from '@emotion/core';
import PropTypes from 'prop-types';
import StyleColors from '../utils/StyleColors';

const cardStyleDefault = css`
    background-color: ${StyleColors.ui01};
    box-shadow: 0px 4px 4px rgba(81,153,211,0.04);
    border-radius: 4px;
    color: ${StyleColors.brand01};
    padding: 2rem;
    margin: 1rem;
    display:flex;
    flex-direction:column;
    justify-content: space-between;
    font-size: 1.8rem;
    font-weight: 800;
    margin-top: 1rem;       
    
    @media screen and (max-width: 768px) {
      & {      
        font-size: 1.8rem;
        font-weight: 800;
      }
    }
`;

const cardStyleSmall = css`
  height: 12rem;
  min-width: 25rem;
  position:relative;
`;

const cardStyleBig = css`
  flex-direction: column;  
  align-items: center;  
  justify-content: space-evenly;
  text-align: center;
  padding: 0;
  font-size: 2.2rem;
  
  @media screen and (max-width: 768px) {
      & {              
        padding: 2rem;
        font-size: 1.8rem;
      }
    }
`;

const Card = ({type,children,customStyles}) => {

  let cardStyle = "";
  if(type === "big"){
    cardStyle = cardStyleBig;
  }else if(type === "small"){
    cardStyle = cardStyleSmall;
  }

  const cardClass = [cardStyleDefault, cardStyle, customStyles];

  return (
    <div css={cardClass} className="css-card">
      {children}
    </div>
  )
};


export const CardHeader = ({title}) => {
  const headerStyle = css`
    font-size: 1.8rem;
    font-weight: 400;
    margin-bottom: 1rem;
  `;
  return (
    <div css={headerStyle}>{title}</div>
  )
};

export const CardHeaderWithButton = ({title, button}) => {
  const headerStyle = css`
    display:flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1.5rem;
    font-weight: 400;
    margin-bottom: 1rem;
  `;

  return (
    <div css={headerStyle}>
      <p>{title}</p>
      {button}
    </div>
  )
};

export const CardImage = ({type, image}) => {
  const smallImageStyle = css`
    align-items: center;
    position: absolute;
    display: flex;
    /* z-index: -1; */
    bottom: 20px;
    right: 11px;
    
    i {
      color: ${StyleColors.brand03};
    }
  `;

  const bigImageStyle = css`
    height: 6rem;   
    display:flex; 
    margin-bottom:2rem;
    
    img {
      max-width: 100%;
    }
    
    i {
      color: ${StyleColors.brand03};
    }
  `;

  return (
    <div css={type === "big" ? bigImageStyle : smallImageStyle}>
      {image}
    </div>
  )
};

Card.propTypes = {
  type: PropTypes.string,
  customStyles: PropTypes.any
};

CardHeader.propTypes = {
  title: PropTypes.string.isRequired
};

CardHeaderWithButton.propTypes = {
  title: PropTypes.string.isRequired,
  button: PropTypes.any.isRequired
};

CardImage.propTypes = {
  image:PropTypes.any.isRequired,
  type: PropTypes.string
};

export default Card;