import axios from 'axios';
import querystring from 'querystring';
import {BASE_URL_RELIANCE, getCookie,DOMAIN_COOKIE_NAME} from './utilityService';

const BASE_URL = BASE_URL_RELIANCE;

export class AffiliateService {
  constructor(customUrl) {
    this.ax = axios.create({
      baseURL: customUrl ? `${BASE_URL}/${customUrl}` : `${BASE_URL}/affiliates`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization':`Bearer ${getCookie(DOMAIN_COOKIE_NAME)}`
      }
    });
    this.data = {};
  }

  ENDPOINTS = {
    base:`${BASE_URL}/affiliates`,
    overview:"/overview",
    profile:"/profile",
    reports:{
      dailySummary:"/reports/daily-summary",
      summary:"/reports/summary",
      products:"/reports/products",
      purchases:"/reports/purchases"
    },
    wallet: {
      balance: "/wallet/balance",
      transactions: "/wallet/transactions",
      cashOut: "/wallet/cash-out"
    },
    transactions:"/transactions",
    materials:"/assets/materials",
    images: (productId) => {
      return `/assets/materials/${productId}/images`
    },
    changePassword: "/change-password"
  };

  post = (url, data) => {
    return this.ax.post(url, data)
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw new AffiliateServiceException(e);
      });
  };

  put = (url, data) => {
    return this.ax.put(url, data)
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw new AffiliateServiceException(e);
      });
  };

  get = (url,data) => {
    return this.ax.get(`${url}?${querystring.stringify(data)}`)
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw new AffiliateServiceException(e);
      });
  };
}

function AffiliateServiceException(e, _meta) {
  if (!e.response)
    e = {response: {status: 0, statusText: "Unknown Error", data: {message: "An Error Occurred"}}};
  this.code = e.response.status;
  this.message = e.response.data.message || e.response.statusText;
  this.meta = _meta;
}

export default new AffiliateService();
