import React from 'react';
import styled from '@emotion/styled';
import MainContent from '../common/MainContent';
import PageHeader from '../common/PageHeader';
import PageBody from '../common/PageBody';
import Card from "../common/Card";
import Accordion from "../common/Accordion";

class FaqContainer extends React.Component {


  render(){
    const {user} = this.props;

    return (
      <MainContent>
        <PageHeader title="Help"/>
        <PageBody>
          <RowStyle>
            <Card customStyles={{flex:'1',height:"auto",margin:"0",padding:"1rem"}}>
              <h2 style={{paddingLeft:"2rem",margin:"1rem 0"}}>
                Frequently Asked Questions
                <p style={{fontSize:"1rem",fontStyle:"italic",color:"#ccc"}}>(Click on a question to see details)</p>
              </h2>
              <div style={{marginTop:"1rem"}}>
                <Accordion
                  open
                  title="How do I go about setting up an account?"
                  content="Click the Sign-up button and complete the easy registration form to get started. You will receive a verification email that enables you access your affiliate dashboard and start selling."/>
                <Accordion
                  title="I just signed up as an affiliate. Now what do I do?"
                  content={`Log into your affiliate account to gain access to the marketing materials. Click on ‘Marketing Materials’ to find promotional banners and links. There you have access to banners and links you can add to your website, share with your friends on whatsapp, social media or your customers. When someone clicks on that link and purchases a Health plan you earn a ${user.affiliateInfo.commissionRate || "7.5"}% commission.`}/>

                <Accordion
                  title="How much will I be paid?"
                  content={`How much you are paid is completely up to you, the more you sell the more your commissions. Your commission rate is ${user.affiliateInfo.commissionRate || "7.5"}% of every health insurance plan sold.`}/>

                <Accordion
                  title="What sales do you pay commissions for?"
                  content="We pay for all health insurance sales that are purchased through your affiliate link or affiliate banners as long as they are deemed valid at the time of your pay out."/>


                <Accordion
                  title="When do I get paid?"
                  content="While the sale gets reflected on your dashboard immediately, there is usually a 48 hours delay before a sale is available as pay-out. Then you can request a payout on the ‘Transactions menu’ by clicking on the ‘My wallet’ button."/>

                <Accordion
                  title="Do I get paid if a customer I referred re-subscribes?"
                  content="Yes you do. When a customer buys a monthly or quarterly subscription you get commissions from their subscription renewals."/>

                <Accordion
                  title="What products on your website can I promote?"
                  content="You get sales commissions for selling the health insurance plans for Individuals Health Plans, Family Health plans and Pre-existing condition plans."/>

                <Accordion
                  title="Can I use PPC campaigns like Google Ads to promote the RelianceHMO health plans?"
                  content="Yes you can. It is however against our policy for affiliates to bid on our brand name, or any keyword variations that includes Reliance, RelianceHMO and Reliance Health Insurance."/>

                <Accordion
                  title="How does the RelianceHMO affiliate customer tracking work?"
                  content="On the affiliate accounts dashboard you are given a unique URL (link), this tracking link tells our systems which affiliate referred a customer to the RelianceHMO website. In situations where the person does not buy immediately but comes back within 30 days on the same device our affiliate system will credit you with the sale. Please take note this tracking only works when the person is not browsing incognito or making use of anti-tracking software."/>

                <Accordion
                  title="How do I get my unique affiliate links?"
                  content="Once you have signed up as an affiliate you can access the Overview page to get your affiliate links and marketing materials."/>

                <Accordion
                  title="Why are my referrals not showing up on the dashboard?"
                  content="This could occur when you give out incorrect tracking links, when the person who used your link makes use of an incognito browser or makes use of cookie blocker."/>

                <Accordion
                  title="My question isn’t listed. How do I get in touch with you?"
                  content="If you need to get in touch about your commissions, payment, registration or any other issue. You can easily reach out to us via affiliate@reliancehmo.com"/>
              </div>
            </Card>
            <Card customStyles={{height:"auto",justifyContent:"flex-start"}}>
              <h2>Contact Us</h2>
              <div style={{margin:"2rem 0",maxWidth:"40rem",wordBreak:"break-word",fontWeight:"normal"}}>
                <p>
                  If you have any issues
                  our Reliance Help Centre agents are always happy to help via:
                </p>
                <br/>
                Email: <a href="mailto:hello@reliancehmo.com?Subject=Affiliate Enquiry">hello@reliancehmo.com</a>
                <br/>
                Telephone: <a href="tel:070073542623">0700-7354-2623</a>
                <br/>
                Whatsapp: <a href="tel:07035369587">017001580</a>
                <br/>
                Facebook: <a href="https://www.facebook.com/pg/reliancehmo">@reliancehmo</a>
                <br/>
                <br/>
                Thanks for choosing RelianceHMO.
              </div>
            </Card>
          </RowStyle>
        </PageBody>
      </MainContent>
    )
  }
}

const RowStyle = styled.div`
  display: flex;
  width:100%;
  justify-content:space-between;
  
  @media screen and (max-width: 768px) {
    & {
      flex-direction: column;
    }
`;

export default FaqContainer;