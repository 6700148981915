import React from 'react';
import styled from '@emotion/styled';
import StyleColors from '../utils/StyleColors';
import LOGO from '../img/logo_icon.svg';
import {getTourConfigData, setTourConfigData, skipAll} from '../utils/TourSteps';

const Wrapper = styled.div`
  position:fixed;
  width:100%;
  height:100%;
  display:flex;
  align-items:center;
  justify-content: center;
  
  &:after {
    content: "";
    background: url(https://www.colourbox.com/preview/19412027-hello-word-cloud-in-different-languages-of-the-world.jpg);
    
    background-position: center;
    opacity: 0.05;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -2; 
  }  
`;

const Content = styled.div`  
  display: flex;
  flex-direction: column;
  align-items: center;
  position:relative;
  
  &:before {
    background: white;
    position: absolute;
    left:0;
    top:0;
    width: 100%;
    height: 100%;
    content: '';
    opacity:0.5;
    z-index:-1;
    border-radius: 3rem;
  }
`;

const Logo = styled.div`
  margin-bottom:1rem;
`;

const Header = styled.h1`
  font-size: 1.8rem;
  color: ${StyleColors.brand02};
  margin-bottom: 1rem;
`;

const Text = styled.div`
    font-size: 1.5rem;
    line-height: 2.5rem;
    max-width: 50rem;
    text-align: center;
    padding: 0 2rem;
`;

const Button = styled.button`
  margin: 1rem;
  border: none;
  background: transparent;
  font-size: 1.5rem;
  cursor: pointer;
  color: ${StyleColors.brand01}
`;

const onProceedClicked = () => {
  let data = getTourConfigData();
  data.showWelcomeTour = false;
  setTourConfigData(data);
  goToOverview();
};

const onSkippedClicked = () => {
  skipAll();
  goToOverview();
};

const goToOverview = ()=> {
  window.location.replace ("/overview");
};

export const WelcomeContainer = () => (
  <Wrapper>
    <Content>
      <Logo>
        <img src={LOGO} alt="RelianceHMO"/>
      </Logo>
      <Header>Hey there!</Header>
      <Text>
        Welcome to the RelianceHMO Affiliate Dashboard.<br/> Here you can view performance updates, get marketing materials, cash-out earnings etc.
        If its your first time here, click the "<strong>GET STARTED</strong>" button below. <br/>
        If you've been here before, and already know your way around the dashboard, click the "<strong>SKIP</strong>" button to continue to your dashboard.
      </Text>
      <Button className="btn primary" onClick={onProceedClicked}>
        GET STARTED
      </Button>
      <Button onClick={onSkippedClicked}>
        SKIP
      </Button>
    </Content>
  </Wrapper>
);