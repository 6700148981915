import React from 'react';
import {Field,reduxForm, change} from 'redux-form';
import PropTypes from 'prop-types';
import UtilityService from '../../services/utilityService';

class UserProfileForm extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      banks:[]
    }
  }

  componentDidMount(){
    const {dispatch, user} = this.props;

    dispatch(change('userProfileForm', 'first_name', user.firstName));
    dispatch(change('userProfileForm', 'last_name', user.lastName));
    dispatch(change('userProfileForm', 'phone_number', user.phoneNumber));
    if(user.bankInfo.bankId){
      this.setState({
        banks:[
          {
            id:user.bankInfo.bankId,
            name: user.bankInfo.bankName
          }
        ]
      });
      dispatch(change('userProfileForm','bank_id',user.bankInfo.bankId));
      dispatch(change('userProfileForm','account_name',user.bankInfo.accountName));
      dispatch(change('userProfileForm','account_number',user.bankInfo.accountNumber));
    }
    dispatch(change('userProfileForm','twitter_handle',user.affiliateInfo.twitterHandle));
    dispatch(change('userProfileForm','instagram_handle',user.affiliateInfo.instagramHandle));
    dispatch(change('userProfileForm','website',user.affiliateInfo.website));

    UtilityService.getBanks()
      .then(response=>{
        this.setState({
          banks:response
        })
      })

  }

  render(){

    const {handleSubmit, pristine, submitting} = this.props;
    const {banks} = this.state;


    return (
      <form className="user-details-form" onSubmit={handleSubmit} >
        <div className="form-wrapper">
          <div className="form-1">
            <div className="form-group">
                <label htmlFor="first_name">
                  First Name
                  <span 
                    data-tooltip="Your first name will be shown to users you refer" 
                    data-tooltip-position="bottom"
                    className="tooltip">
                      <i className="fa fa-info-circle"/>
                  </span>
                </label>
                <Field component="input" className="profile_input" type="text" id="first_name" name="first_name" placeholder="First Name" required/>
            </div>
            <div className="form-group">
                <label htmlFor="last_name">Last Name</label>
                <Field component="input" className="profile_input" type="text" id="last_name" name="last_name" placeholder="Last Name" required/>
            </div>
            <div className="form-group">
                <label htmlFor="phone_number">Phone Number</label>
                <Field component="input" className="profile_input" type="text" id="phone_number" name="phone_number" placeholder="08092080585" required />
            </div>
          </div>

          <div className="form-2">
            <div className="l-form">
              <label htmlFor="bank_id">Bank Name</label>
              <div className="select" style={{marginTop:"1rem"}}>
                <Field name="bank_id" className="form-input" component="select" required>
                  <option>Please select a Bank</option>
                  {banks.map((bank) => (
                    <option key={bank.id} value={bank.id}>{bank.name}</option>
                  ))}
                </Field>
              </div>
            </div>
            <div className="form-group">
                <label htmlFor="account_name">Account Name</label>
                <Field component="input" className="profile_input" type="text" id="account_name" name="account_name" placeholder="Account Name" required/>
            </div>
            <div className="form-group">
                <label htmlFor="account_number">Account Number</label>
                <Field component="input" className="profile_input" type="nummber" id="account_number" name="account_number" placeholder="2990404709" max={10} required/>
            </div>
          </div>
        </div>

        <div className="form-1">
          <span>
              <label htmlFor="instagram_handle">Instagram</label>
              <Field component="input" className="profile_input" type="text" id="instagram_handle" name="instagram_handle" placeholder="@reliancehmo" />
          </span>
          <span>
              <label htmlFor="twitter_handle">Twitter</label>
              <Field component="input" className="profile_input" type="text" id="twitter_handle" name="twitter_handle" placeholder="@reliancehmo" />
          </span>
          <span>
              <label htmlFor="website">Website</label>
              <Field component="input" className="profile_input" type="website" id="website" name="website" placeholder="https://www.reliancehmo.com" />
          </span>
        </div>

        <button className="profile_input_button" disabled={pristine || submitting}>Save Information</button>
      </form>
    )
  }
}

UserProfileForm.propTypes = {
  onSubmit:PropTypes.func
};

UserProfileForm = reduxForm({
  form: "userProfileForm",
  destroyOnUnmount:false,
})(UserProfileForm);

export default UserProfileForm;