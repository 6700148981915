import React from 'react';
import {Field, reduxForm} from 'redux-form';

class ChangePasswordForm extends React.Component {

  render() {
    const {handleSubmit, pristine, submitting} = this.props;

    return (
      <form className="change-pwd-form" onSubmit={handleSubmit} >
        <div className="form-wrapper">
          <div className="form">
            <span>
                <label htmlFor="">Old Password</label>
                <Field component="input" className="pwd-input" type="password" id="old_password" name="old_password" placeholder="" required/>
            </span>
            <span>
                <label htmlFor="">New Password</label>
                <Field component="input" className="pwd-input" type="password" id="new_password" name="new_password" placeholder="" required/>
            </span>
            <span>
                <label htmlFor="">Confirm New Password</label>
                <Field component="input" className="pwd-input" type="password" id="confirm_new_password" name="confirm_new_password" placeholder="" required />
            </span>
          </div>
        </div>
        <button className="pwd-input_button" disabled={pristine || submitting}>Change Password</button>
      </form>
    )
  }
}


ChangePasswordForm = reduxForm({
  form: "changePasswordForm"
})(ChangePasswordForm);

export default ChangePasswordForm;
