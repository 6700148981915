import React from 'react';
import jsCookie from 'js-cookie';
import StyleColors from '../utils/StyleColors';

const cookieKey = "rhmo_awtc";


export const defaultTourConfigData = {
  showWelcomeTour:true,
  showOverviewTour:true,
  showAssetsTour:true,
  showTransactionsTour:true
};

export const getTourConfigData = () => {
  return jsCookie.getJSON(cookieKey) || defaultTourConfigData;
};

export const setTourConfigData = (data = defaultTourConfigData) => {
  jsCookie.set(cookieKey, data);
};

export const skipAll = () => {
  let data = getTourConfigData();
  Object.keys(data).forEach(key=>{
    data[key] = false;
  });
  setTourConfigData(data);
};


class TourSteps {

  static overviewTourSteps() {
    return [
      {
        title: 'Your Affiliate Link',
        target: '.link-row',
        content: (
          <div>
            Click to copy your affiliate link and share with potential buyers. When a buyer clicks on
            that link and purchases you earn a commission.
          </div>
        ),
        disableBeacon: true
      },
      {
        title: 'Your Sales Performance',
        target: '.sales-row',
        content: (
          <div>
              Whenever your customer clicks your link, 
              makes a purchase or a commission is paid to you this area gets updated.
          </div>
        ),
        disableBeacon: true
      },
      {
        title: 'Cash-out your Earnings',
        target: '.cash-out',
        content: (
          <div>
            Click the cashout button to credit your bank account with your approved commissions.
          </div>
        ),
        disableBeacon: true
      },
      {
        title: 'Know The Products You Are Selling',
        target: '.product-row',
        content: (
          <div>
            Each section in this area is a health insurance product you can promote. It includes the product’s monthly
            price and information about coverage.
          </div>
        ),
        disableBeacon: true
      },
      {
        title: '...and many more',
        target: '#left',
        content: (
          <div>
            There are many other things you can do on your affiliate dashboard, such as: view product performance reports,
            view transaction history, update your profile etc. You can access any of them by clicking from this menu here.
          </div>
        ),
        placement:"right"
      }
      // {
      //   title: 'Click Cashout to Get Paid',
      //   target: '.css-card',
      //   content: (
      //     <div>
      //       Click the cashout button to credit your bank account with your approved commissions. 
      //     </div>
      //   )
      // }
    ];
  }

  static assetsTourSteps() {
    return [
      {
        title: 'Select the group of people you are targeting',
        target: '[data-attr="Select Market"]',
        content: (
          <div>
            Click here to choose the group of people you want to sell to.
            For example if you are interested in targeting people interested in the pre existing conditions cover,
            click ‘Pre-Existing Condition’.
            <br/>
            Click on a group to continue.
          </div>
        ),
        disableOverlayClose: true,
        spotlightClicks: true,
        placement: 'right',
        hideCloseButton: true,
        hideFooter: true,
        disableBeacon: true,
        styles: {
          options: {
            width: 200
          }
        }
      },
      {
        title: 'Copy the targeted link to share with a buyer',
        target: '[data-attr="product url container"]',
        content: (
          <div>
            Click to copy your targeted affiliate link . 
            This targeted link sends the buyer to a particular page. 
            When that buyer purchases you earn a commission
          </div>
        ),
        placement: 'right',
        disableBeacon: true,
        disableOverlayClose: true,
        spotlightClicks: true,
      },
      {
        title: 'Select banner for promoting on your website',
        target: '[data-attr="Banner Sizes"]',
        content: (
          <div>
            If you are promoting on your website, select the banner size you prefer e.g. 728x90.
            <br/>
            Click on a banner size to continue.
          </div>
        ),
        disableBeacon: true,
        hideFooter:true,
        hideCloseButton: true,
        disableOverlayClose: true,
        spotlightClicks: true,
        placement: 'top'
      },
      {
        title: 'Copy banner HTML code for website',
        target: '[data-attr="Banner Embed Code"]',
        content: (
          <div>
            Copy the banner HTML code and add to your website HTML code to make a clickable advert on your website
          </div>
        ),
        disableBeacon: true,
        disableOverlayClose: true,
        spotlightClicks: true,
      }
    ];
  }

  static transactionTourSteps() {
    return [
      {
        title: 'Click on My Wallet',
        target: '[title="My Wallet"]',
        content: 'Click this button to access your Wallet. From here your can view your earnings and also cash-out some money.',
        styles: {
          options: {
            width: 200
          }
        },
        placement: 'top',
        hideCloseButton: true,
        hideFooter: true,
        disableBeacon: true,
        disableOverlayClose: true,
        spotlightClicks: true
      },
      {
        title: 'Available Balance',
        target: '.css-card',
        content: 'This is how much of your earnings is currently available to for you to cash-out',
        hideBackButton: true,
        disableBeacon: true
      },
      {
        title: "Cash-Out",
        target: '.l-form',
        content: 'Enter the amount you want to Cash-out here.',
      }
    ];
  }

  static styleOptions () {
    return {
      primaryColor: StyleColors.colorMainblue,
    };
  }
}

export default TourSteps;