import React from 'react';
import ReactJoyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import {BASE_URL_RELIANCE} from "../services/utilityService";
import numberFormatter from 'number-formatter';
import AsyncTable from '../common/AsyncTable';
import MainContent from '../common/MainContent';
import PageHeader from '../common/PageHeader';
import PageBody from '../common/PageBody';
import Modal from "../common/Modal";
import WalletComponent from "../components/WalletComponent";
import Card from "../common/Card";
import TourSteps, {getTourConfigData, setTourConfigData}  from '../utils/TourSteps';

const tableColumnData = [
  {
    key:"created_at",
    type:"date",
    alias:"date"
  },
  {
    key:"type",
    type:"string"
  },
  {
    key:"amount",
    type:"number",
    alias:"amount(₦)",
    format:(data)=>{
      return numberFormatter("#,##0.00",data)
    }
  },
  {
    key:"balance",
    type:"number",
    alias:"balance(₦)",
    format:(data)=>{
      return numberFormatter("#,##0.00",data)
    }
  },
  {
    key:"transaction_reference",
    type:"string",
    alias:"reference id"
  },

  {
    key:"payment_status",
    type:"string",
    alias:"status"
  }
];

class PayoutContainer extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      showWalletModal: false,
      run: false,
      tourSteps: [],
      stepIndex: 0
    };
  }

  componentDidMount() {
    this.setState({
      run: getTourConfigData().showTransactionsTour || false,
      tourSteps: TourSteps.transactionTourSteps()
    })
  }

  toggleWalletModal = () => {
    const { showWalletModal, stepIndex } = this.state;

    this.setState({
      showWalletModal: !showWalletModal
    }, ()=>{
      setTimeout(()=>{
        this.setState({stepIndex: stepIndex === 0 ? 1 : stepIndex})},1000)
    })    
  };

  refreshPage = () => {
    window.location.reload();
  };

  handleJoyrideCallback = data => {
    const { action, index, type, status } = data;

  
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      // Need to set our running state to false, so we can restart if we click start again.
      this.setState({ run: false, stepIndex: 0 });

      //update tour config
      let data = getTourConfigData();
      data.showTransactionsTour = false;
      setTourConfigData(data);
    }else if([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      const stepIndex = index + (action === ACTIONS.PREV ? -1 : 1);
      this.setState({stepIndex});
    }
  };

  render () {
    const {user} = this.props;
    const {showWalletModal, tourSteps, run, stepIndex} = this.state;

    return (
      <MainContent>

        {showWalletModal &&
        <WalletModal onClose={this.refreshPage}/>
        }

        <PageHeader title="Transactions" button={<WalletComponent type="button"/>}/>
        <PageBody>
          <Card type="big" customStyles={{alignItems:"stretch",height:"80vh",justifyContent:"space-between"}}>
            <AsyncTable
              dataSource={{
                url:`${BASE_URL_RELIANCE}/affiliates/wallet/transactions`,
                options:{
                  headers:{
                    "Authorization":`Bearer ${user.credentials.accessToken}`
                  }
                }
              }}
              isPaginated
              limit={10}
              paginationKeys={{
                limit:"limit",
                page:"page",
                lastPage:"last_page",
                currentPage:"current_page"
              }}
              tableColumnData={tableColumnData}/>
          </Card>
        </PageBody>
        <ReactJoyride
          run={run}
          steps={tourSteps}
          stepIndex={stepIndex}
          callback={this.handleJoyrideCallback}
          scrollToFirstStep
          continuous
          showSkipButton
          showProgress
          styles={{
            options: TourSteps.styleOptions()
          }}
        />
      </MainContent>
    )
  }
}

const WalletModal = ({onClose}) => (
  <Modal title="Wallet" onClose={onClose}>
    <WalletComponent/>
  </Modal>
);

export default PayoutContainer;