import React  from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route
} from 'react-router-dom';
import DashboardContainer from './containers/DashboardContainer';
import './App.css';
import LogoutContainer from "./containers/LogoutContainer";
import {withUser} from "./utils/AuthHOC";
import {WelcomeContainer} from "./containers/WelcomeContainer";

const App = () => (
    <Router history="">
      <Switch>
        <Route component={LogoutContainer} path="/logout" exact />
        <Route component={WelcomeContainer} path="/welcome" exact />
        <Route path="/" component={withUser(DashboardContainer)}/>
      </Switch>
    </Router>
);

export default App;
