import { createTypes, async } from 'redux-action-types';
import * as ACTION_CATEGORIES from './actionCategories';

export const USER_ACTION_TYPES = createTypes(ACTION_CATEGORIES.USER+'_',
    async('LOGIN'),
    async('DAARA_LOGOUT'),
    async('RESET_PASSWORD'),
    async('UPDATE_PROFILE'),
    'START_LOADING',
    'STOP_LOADING'
);

export const DISPLAY_ACTION_TYPES = createTypes(ACTION_CATEGORIES.DISPLAY+'_',
    'TOGGLE_SIDEBAR',
    'HIDE_SIDEBAR'
);