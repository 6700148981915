/** @jsx jsx */
import React from 'react';
import affiliateService, { AffiliateService } from '../services/affiliateService';
import MainContent from "../common/MainContent";
import PageBody from "../common/PageBody";
import { css, jsx } from "@emotion/core";
// import CenteredContentBlock from "../common/CenteredContentBlock";
import ChangePasswordForm from "./forms/ChangePasswordForm";
import PageHeader from "../common/PageHeader";
import LoadingScreen from "../common/LoadingScreen";
import NotificationBanner from "../common/NotificationBanner";

const change_password = css`
  .dashboard-change-pwd {
    background-color: #FFFFFF;
    padding: 2rem;
    border-radius: 4px;
    //flex-wrap: wrap;
    // border: 1px solid rgba(9, 64, 99, 0.1);
    margin: 2rem;
    justify-content: space-between;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);

    form {
    display: flex;
    flex-direction: column;
    }

    .pwd-input {
    border: 1px solid #E5E5E5;
    border-radius: 4px;
    padding: 1rem 1.2rem;
    width: 30rem;
    margin-top: 1rem;
    }

    .pwd-input_button {
    width: 150px;
    color: white;
    background-color: #094063;
    border-radius: 4px;
    font-size: 15px;
    font-weight: 600;
    padding: 10px 10px;
    text-decoration: none;
    margin-top: 2rem;
    }
  }

  .dashboard_change-pwd_form {
    .change-pwd-form {
      margin-bottom: 20px;
    }

    .form-wrapper {
      display: flex;
      // flex-direction: column;
    }
  
    .form {
      margin-right: 2rem;
      display: flex;
      flex-direction: column;
      
      span {
        display: flex;
        flex-direction: column;
        margin-bottom: 1rem;
      }
  
      label {
        font-size: 1.4rem;
        font-weight: 600;
        color: #4F4F4F;
      }
  
      input::placeholder {
        font-size: 1.5rem;
        font-weight: 800;
        color: #E5E5E5;
      }
    }
  }

  @media screen and (max-width: 768px) {
    .dashboard_profile {
      flex-direction: column;
  
      .pwd-input {
        width: 100%;
      }

      .form {
        margin: 0;
      }
  
      .form-wrapper {
        flex-direction: column;
      }
  
      img {
        align-self: center;
        max-width: 100%;
        height: auto;
      }
    }
  }
`;

class ChangePasswordComponent extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      notification: null,
      isLoading:false
    }
    this.affiliateService =  new AffiliateService("accounts");
  }
  
  onSubmit = (values) =>{
    if(values.new_password !== values.confirm_new_password){
      this.setNotification({
        title: "Warning!",
        type:"warn",
        message: "New password and Confirm new password are not the same"
      })
    }else{
      this.setState({
        isLoading:true
      });

      this.affiliateService.post(affiliateService.ENDPOINTS.changePassword, values) 
        .then(response=>{
          this.setLoading(false);
          this.setNotification({
            title: "Success",
            message: response.data.message,
            type: response.status
          })
        })
        .catch((error)=>{
          this.setLoading(false);
          this.setNotification({
            title: "Error!",
            message: error.message,
            type: "error"
          })
        });
    }
  };

  onDismiss = () => {
    this.setState({
      notification: null
    });
  };

  setNotification = (notification) => {
    this.setState({
      notification
    });
    setTimeout(() => this.onDismiss(), 4000);
  };

  setLoading = (isLoading) => {
    this.setState({
      isLoading
    });
  };

  renderLoading = () => {
    return (
      <MainContent>
        <PageHeader title="Change Password"/>
        <PageBody>
          <LoadingScreen/>
        </PageBody>
      </MainContent>
    )
  };

  render(){
    const {isLoading, notification} = this.state;

    if(isLoading) return this.renderLoading();

    return (
      <MainContent>
        <PageHeader title="Change Password" />
        <PageBody>
          {notification &&
          <NotificationBanner
            title={notification.title.toUpperCase()}
            message={notification.message}
            type={notification.type}/>
          }

          <div css={change_password}>
            <div className="dashboard-change-pwd dashboard_change-pwd_form ">
              <ChangePasswordForm onSubmit={this.onSubmit} />
            </div>
          </div>
        </PageBody>
      </MainContent>
    )
  }
}

export default ChangePasswordComponent;