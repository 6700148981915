import React from 'react';
import DailySummary from '../components/DailySummary';
import Purchases from '../components/Purchases';
import PropsRoute from "../utils/PropsRoute";

class ReportContainer extends React.Component {
  render () {
    const {user} = this.props;
    return (
      <React.Fragment>
        <PropsRoute path='/reports/activity' component={DailySummary} user={user} exact/>
        <PropsRoute path='/reports/purchases' component={Purchases} user={user} exact/>
      </React.Fragment>
    )
  }
}

export default ReportContainer;