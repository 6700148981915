import React from 'react';
import styled from '@emotion/styled'
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {DISPLAY_ACTION_TYPES} from '../actions/actionTypes';
import StyleColors from "../utils/StyleColors";

class PageHeader extends React.Component {

  toggleSideBar = () => {
    const {dispatch} = this.props;
    dispatch({type: DISPLAY_ACTION_TYPES.TOGGLE_SIDEBAR});
  };

  render() {
    const {title, showButton, onButtonClick, buttonTitle, button} = this.props;

    return (
      <PageHeaderStyles>
        <div className="header__left">
          <button className="toggle-nav" onClick={this.toggleSideBar}>&#9776;</button>
        </div>
        <div className="header__title">
          <h2>{title}</h2>
        </div>
        <HeaderRight showButton={showButton} onButtonClick={onButtonClick} buttonTitle={buttonTitle} button={button}/>
      </PageHeaderStyles>
    );
  }
}

const HeaderRight = ({showButton, onButtonClick, buttonTitle, button}) => {

  return (
    <div className="header__right">
      {button ? button : showButton && <button title={buttonTitle} className="btn primary" onClick={onButtonClick}>{buttonTitle}</button>}
      <div className="header__notif" style={{display: "none"}}>
        <button className="badge notif" data-badge="1" aria-hidden="true"><i className="fa fa-bell"/></button>
      </div>
    </div>
  )
};

PageHeader.propTypes = {
  title: PropTypes.string,
  button: PropTypes.element,
  showButton: PropTypes.bool,
  onButtonClick: PropTypes.func,
  buttonTitle: PropTypes.string
};

const PageHeaderStyles = styled.div`
  flex-shrink: 0;
  display: inline-flex;
  position:fixed;
  z-index:1;
  top:0;
  height: 8rem;
  background-color: #fff;
  width: 80%;
  padding: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
    
  .header__left {
    display: none;
    align-items: center;
    
    .toggle-nav {
        display: none;
        background:none;
        border:none;
    }
  }
    
  .header__title {
    h2 {
      font-size: 2rem;
      font-style: normal;
      font-weight: 800;
      color: #094063;
    }
  }
    
  .header__right {
    display: flex;
    align-items: center;
    justify-content: center;
    
    button {
      border-radius: 4px;
      font-size: 1.6rem;
      padding: 1rem;
      font-style: normal;
      font-weight: normal;
      background-color: #094063;
      color: #fff;
      border: 0;
      margin: 0 1rem;
    }
  
    .header__notif {
      .notif {
        border: 0;
        background-color: ${StyleColors.ui01};
        color: #094063;
      }
      
      button {
        width: 2rem;
        height: 2rem;
        border: none;
        background: none;
      }
    }
  }
    
  .badge {
    position: relative;
  }
    
  .header__search {
      width: 16rem;
      height: 4rem;
      input {
          border-radius: 4px;
          width: 100%;
          height: 100%;
          border: 1px solid ${StyleColors.brand01};
          padding: 1rem;
      }
  }
  
  @media screen and (max-width: 768px) {
    & {
      padding: 2rem;
      width:100%;
      .header__left {
        display: flex;
        align-items: center;

        .toggle-nav {
          display: flex;
          color: ${StyleColors.brand01};
          font-size: 2rem;
          border: none;
          margin-right: 1rem;
          background-color: ${StyleColors.ui01}
        }
      }
      
      .header__title {
        h2 {
          font-size: 1.8rem;
        }
      }
      
      .header__right {
        justify-content: space-between;
        button {
          border-radius: 3px;
          font-size: 1.2rem;
        }
  
        .header__search {
          width: 9rem;
          height: 3rem;
          input {
              border-radius: 3px;
              width: 100%;
              height: 100%;
          }
        }
      }        
    }
  }
`;


export default connect()(PageHeader)