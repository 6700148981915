import axios from 'axios';
import Cookies from 'js-cookie';
import * as config from '../config';
import { states, occupations, maritalStatuses } from './constants';

export const BASE_URL_RELIANCE = config.BASE_URL_RELIANCE;

export const DOMAIN_COOKIE_NAME = config.DOMAIN_COOKIE_NAME;

export const DAARA_COOKIE_NAME = 'DAARA';

export const DAARA_TOKEN_PREFIX = process.env.REACT_APP_DAARA_TOKEN_PREFIX || 'ACCOUNT';

export const REFRESH_TOKEN_NAME = process.env.REACT_APP_REFRESH_NAME;

export const getAccountsDashboardUrl = () => {
    switch (config.ENV) {
        case 'local':
            return 'http://localhost:3000';
        case 'daara':
            return 'https://accounts.daara.develop.reliancehmo.com';
        case 'daara-staging':
            return 'https://accounts.daara.staging.reliancehmo.com';
        case 'v3test':
        case 'development':
            return 'https://accounts.develop.reliancehmo.com';
        case 'staging':
            return 'https://accounts.staging.reliancehmo.com';
        case 'production':
            return 'https://accounts.reliancehmo.com';
        default:
            return 'https://accounts.reliancehmo.com';
    }
};

export const getCookie = () => {
    return Cookies.get(DOMAIN_COOKIE_NAME);
};

class UtilityService {
    static getOccupations() {
        return Promise.resolve(occupations);
        //return axios.get(`${BASE_URL_RELIANCE}/utility/occupations`);
    }

    static getStates() {
        return Promise.resolve(states);
        //return axios.get(`${BASE_URL_RELIANCE}/utility/states`);
    }

    static getMaritalStatuses() {
        return Promise.resolve(maritalStatuses);
        //return axios.get(`${BASE_URL_RELIANCE}/utility/marital_statuses`);
    }

    static getUtilities() {
        return Promise.all([
            UtilityService.getOccupations(),
            UtilityService.getStates(),
            UtilityService.getMaritalStatuses()
        ]).then((responses) => {
            return {
                occupations: responses[0].data,
                states: responses[1].data,
                mStatuses: responses[2].data
            };
        });
    }

    static getPlans() {
        return axios.get(`${BASE_URL_RELIANCE}/plans`).then((response) => {
            return response.data.data;
        });
    }

    static getProviders(withExtensions = false, planId) {
        return axios
            .get(
                `${BASE_URL_RELIANCE}/providers?with_extensions=${withExtensions}${
                    planId ? '&plan_id=' + planId : ''
                }`
            )
            .then((response) => {
                return response.data.data;
            });
    }

    static getExtensions() {
        return axios.get(`${BASE_URL_RELIANCE}/extensions`).then((response) => {
            return response.data.data;
        });
    }

    static getBanks() {
        return axios.get(`${BASE_URL_RELIANCE}/utility/banks`).then((response) => {
            return response.data.data;
        });
    }

    static uploadFile(accessToken, file, uploadType) {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('file_use', uploadType);
        return axios
            .post(`${BASE_URL_RELIANCE}/upload`, formData, {
                header: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${accessToken}`
                }
            })
            .then((response) => {
                return response.data.data.filename;
            })
            .catch((e) => {
                return {
                    message: e.response.data.message || 'An Error Occurred',
                    code: e.response.status
                };
            });
    }
}

export default UtilityService;
