import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import MainContent from '../common/MainContent';
import PageHeader from '../common/PageHeader';
import PageBody from '../common/PageBody';

const notifications = [ 
  {
    createdAt: "2009-02-04 22:13:00",
    title: "New fights",
    summary: "A fight broke out two days ago in the main board room",
    content: "The day before this was an interesing day, A fight broke out and two people where found injured and battered in the board room",
    author: "Matthew Mayaki"
  },
  {
    createdAt: "2019-02-09 02:3:00",
    title: "Merry Christmas greetings",
    summary: "A fight broke out two days ago in the main board room",
    content: "The day before this was an interesing day, A fight broke out and two people where found injured and battered in the board room",
    author: "ThatAwesomeGuy"
  },
  {
    createdAt: "2019-02-09 09:13:00",
    title: "Lorem Ipsum",
    summary: "A fight broke out two days ago in the main board room",
    content: "The day before this was an interesing day, A fight broke out and two people where found injured and battered in the board room",
    author: "ThatAwesomeGuy"
  },
  {
    createdAt: "2019-02-09 23:05:00",
    title: "Lorem Ipsum",
    summary: "A fight broke out two days ago in the main board room",
    content: "The day before this was an interesing day, A fight broke out and two people where found injured and battered in the board room",
    author: "ThatAwesomeGuy"
  },
  {
    createdAt: "2009-02-04 22:13:00",
    title: "New fights",
    summary: "A fight broke out two days ago in the main board room",
    content: "The day before this was an interesing day, A fight broke out and two people where found injured and battered in the board room",
    author: "Matthew Mayaki"
  },
  {
    createdAt: "2009-02-04 22:13:00",
    title: "New fights",
    summary: "A fight broke out two days ago in the main board room",
    content: "The day before this was an interesing day, A fight broke out and two people where found injured and battered in the board room",
    author: "Matthew Mayaki"
  },
  {
    createdAt: "2009-02-04 22:13:00",
    title: "New fights",
    summary: "A fight broke out two days ago in the main board room",
    content: "The day before this was an interesing day, A fight broke out and two people where found injured and battered in the board room",
    author: "Matthew Mayaki"
  },
  {
    createdAt: "2009-02-04 22:13:00",
    title: "New fights",
    summary: "A fight broke out two days ago in the main board room",
    content: "The day before this was an interesing day, A fight broke out and two people where found injured and battered in the board room",
    author: "Matthew Mayaki"
  }
];

class NotificationsList extends Component {
  constructor () {
    super()
    this.state = {
      notificationsMetaData: notifications
    }
  }

  displayNotificationPage = () => {

  }

  render() {
    const { notificationsMetaData } = this.state;
    return (
      <MainContent>
        <PageHeader title="Notifications"/>
        <PageBody>
          <div className="notifications-list">
            {
              notificationsMetaData.map((notification, i) => (
                  <Link className="notification-card" key={i+1} to={"/notification-detail"}>
                    <h3 className="notification-title">{notification.title}</h3>
                    <p className="notification-content">{notification.content.substring(0, 100) + '...'}</p>
                  </Link>
              ))
            }
          </div>
        </PageBody>
      </MainContent>
    );
  }
}

export default NotificationsList;