import React from 'react';
import numberFormatter from 'number-formatter';
import affiliateService from '../services/affiliateService';
import MainContent from '../common/MainContent';
import PageHeader from '../common/PageHeader';
import PageBody from '../common/PageBody';
import AsyncTable from "../common/AsyncTable";
import Card from "../common/Card";



class Purchases extends React.Component {

  render () {

    const {user} = this.props;

    const tableColumnData = [
      {
        key:"date",
        type:"date"
      },
      {
        key:"invoice_code",
        type:"string"
      },
      {
        key:"description",
        type:"string"
      },
      {
        key:"amount",
        alias:"amount(₦)",
        type:"number",
        format:(data)=>{
          return numberFormatter("#,##0.00",data)
        }
      },
      {
        key:"commission",
        alias:"commission(₦)",
        type:"number",
        format:(data)=>{
          return numberFormatter("#,##0.00",data)
        }
      }
    ];

    return (
      <MainContent>
        <PageHeader title="Purchase Report"/>
        <PageBody>
          <Card type="big" customStyles={{alignItems:"stretch",height:"80vh",justifyContent:"space-between"}}>
            <AsyncTable
              isPaginated
              limit={10}
              dataSource={{
                url:`${affiliateService.ENDPOINTS.base}${affiliateService.ENDPOINTS.reports.purchases}`,
                options:{
                  headers:{
                    "Authorization":`Bearer ${user.credentials.accessToken}`
                  }
                }
              }}
              paginationKeys={{
                limit:"limit",
                page:"page",
                lastPage:"last_page",
                currentPage:"current_page"
              }}
              tableColumnData={tableColumnData}/>
          </Card>
        </PageBody>
      </MainContent>
    )
  }
}

export default Purchases;